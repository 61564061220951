import { ClickAwayListener } from "@mui/material";
import { useState } from "react";
import { Container, Nav, Navbar, OverlayTrigger } from "react-bootstrap";
import { FaChevronDown } from "react-icons/fa6";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getInitials } from "../../utils/helper";
import { Button } from "../Button/Button";
import Style from "./DesktopHeader.module.css";
import { useSelector } from "react-redux";

const DesktopHeader = ({
  logo = "/images/Logo.png",
  backgroundColor = "",
  containerClass = "",
  className = "",
  HeaderButtons = [],
  isLoggedIn = false,
  headerLinks = [],
  user = null,
  onLogout = () => {},
  popover = [],
}) => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const pathname = useLocation()?.pathname;
  const { notificationCount } = useSelector(
    (state) => state?.authReducer
  );

  const renderContent = () => {
    if (isLoggedIn) {
      return (
        <>
          <ClickAwayListener onClickAway={() => setShow(false)}>
            <div>
              <OverlayTrigger
                trigger={["click"]}
                placement={"bottom-end"}
                overlay={
                  <ul className={Style.overlay}>
                    {popover?.map((item, index) => (
                      <li
                        key={index}
                        className={[Style.overlayLink].join(" ")}
                        onClick={() => {
                          if (item.label === "Logout") {
                            onLogout();
                            setShow(false);
                          }
                        }}
                      >
                        <Link to={item.path}>{item.label} {item.label == "Notifications" && notificationCount > 0 && `(${notificationCount})`}</Link>
                      </li>
                    ))}
                  </ul>
                }
                show={show}
                onToggle={() => setShow(!show)}
              >
                <div className={Style.headerDropDown}>
                  <div className={[Style.profileImg]}>
                    <span>{getInitials("Admin")}</span>
                  </div>
                  <p>{user?.userRoleId == "1" ? "Admin" : "Sub Admin"}</p>
                  <FaChevronDown color="#5c5c5c" />
                </div>
              </OverlayTrigger>
            </div>
          </ClickAwayListener>
        </>
      );
    }

    return (
      <div className={Style.btnMain}>
        {HeaderButtons?.map((item, index) => (
          <Button
            // onClick={() => router.push(item?.value)}
            label={item?.label}
            customStyle={{
              backgroundColor: item?.value == "/login" && "transparent",
              color: item?.value == "/login" && "var(--secondary-color)",
              border: item?.value == "/login" && "1px solid currentColor",
              padding: "9px 24px",
            }}
            rightIcon={item?.icon}
            key={index}
          />
        ))}
      </div>
    );
  };

  return (
    <>
      <Container
        className={`${[Style.navbarContainer, containerClass].join(
          " "
        )} mainContainer`}
      >
        <Navbar
          collapseOnSelect
          expand="lg"
          className={`${[Style.header, className].join(" ")}`}
          style={{ backgroundColor: backgroundColor }}
          id={"navDesktopHeader"}
        >
          <div
            className={Style.main_logo_main}
            onClick={() => navigate("/dashboard")}
          >
            <img
              src={logo}
              alt="Logo"
              style={{ objectFit: "contain", objectPosition: "left" }}
              height={65}
              width={200}
            />
          </div>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            className={Style.navbarCollapse}
            id="responsive-navbar-nav"
          >
            <Nav
              className={`mx-auto ${[Style.navbarCustom__style].join(" ")}`}
              gap={5}
            >
              {headerLinks?.map((item, index) => (
                <Link
                  key={index}
                  to={item?.value}
                  className={[
                    Style.nabarLinks,
                    pathname === item.value && Style.navActive,
                  ].join(" ")}
                >
                  {item?.label}
                </Link>
              ))}
            </Nav>

            <div className={Style.dflex}>{renderContent()}</div>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </>
  );
};

export default DesktopHeader;
