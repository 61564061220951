import { MdOutlineRequestPage } from "react-icons/md";
import classes from "./ServiceCard.module.css";
import { Button } from "../Button/Button";
import { FaRegCircleUser } from "react-icons/fa6";
import { getFormattedPrice, mergeClass } from "../../utils/helper";
import { FaArrowRightLong } from "react-icons/fa6";
import { Col, Row } from "react-bootstrap";

const ServiceCard = ({
  services,
  showRequests = true,
  showHeader,
  onClick,
  buttonLabel = "View Details",
}) => {
  return (
    <Row className={mergeClass(classes.service_card)}>
    <Col lg={8}>
      <div>
        <p className={mergeClass("maxLine1" , classes.title)} title={services?.serviceCategoryName}>
          {services?.serviceCategoryName}
        </p>
        <h3
          className={mergeClass("maxLine1")}
          title={services?.title}
        >
          {services?.title}
        </h3>
      </div>
      <div className={classes.pricingDiv}>
        <p>Pricing</p>
        <h5>{getFormattedPrice(services?.amount)}</h5>
      </div>
      {showRequests && (
        <p className="d-flex align-items-center gap-2 mt-2">
          <MdOutlineRequestPage size={20} />
          <b> {services?.totalPendingRequests}</b>
          <span>
            <b>Requests</b>
          </span>
        </p>
      )}
    </Col>

    <Col lg={4} className={mergeClass("center")}>
      <Button
        className={classes.btn}
        label={buttonLabel}
        onClick={onClick}
        rightIcon={<FaArrowRightLong size={18} />}
      />
    </Col>
  </Row>
  );
};

export default ServiceCard;

/*
<div className={classes.service_cards_container}>
      <div className={classes.serviceCardMain}>
        {showHeader && (
          <>
            <div className={classes.header}>
              <span className={mergeClass(classes.font, classes.userName)}>
                <strong>{services?.label}</strong>
              </span>
              <span className={mergeClass(classes.font, classes.userName)}>
                {services?.status}
              </span>
            </div>
            <div className={classes.header}>
              <FaRegCircleUser
                size={"30"}
                color="var(--user-icon-color)"
                className={classes.userIcon}
              />
              <span className={mergeClass(classes.font, classes.userName)}>
                {services?.userName}
              </span>
            </div>
          </>
        )}

        <div className={classes.service_card}>
          <div className={classes.subDiv}>
            <div className={classes.titleDiv}>
              <p>{services?.title}</p>
              <h5>{services?.subtitle}</h5>
              {showRequests && (
                <p className="d-flex align-items-center gap-2 mt-2">
                  <MdOutlineRequestPage size={20} />
                  <b> {services?.requests}</b>
                  <span>
                    <b>Requests</b>
                  </span>
                </p>
              )}
            </div>

            <div className={classes.pricingDiv}>
              <p>Pricing</p>
              <h5>{services?.price}</h5>
            </div>
          </div>

          <div>
            <Button
              className={classes.btn}
              label={buttonLabel}
              onClick={onClick}
              rightIcon={<FaArrowRightLong size={18} />}
            />
          </div>
        </div>
      </div>
    </div>*/