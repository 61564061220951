import { baseURL } from "../../config/apiUrl";
import { Get } from "../../Axios/AxiosFunctions";
import { Button } from "../Button/Button";
import DateInput from "../DateInput";
import { DropDown } from "../DropDown/DropDown";
import { Input } from "../Input/Input";
import RenderToast from "../RenderToast";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ModalSkeleton from "../../modals/ModalSkeleton";
import { useSelector } from "react-redux";

const FiltersModal = ({
  show,
  setShow,
  showStatusFilter = false,
  showRequestStatusFilter = false,
  showServiceCategoryFilter = false,
  showAmountFilter = false,
  showDateFilter = false,
  showStartDateFilter = false,
  showEndDateFilter = false,
  showJobFilter = false,
  showRatingFilter = false,
  setFilters = () => {},
  filters = null,
}) => {
  const accessToken = useSelector((state) => state?.authReducer?.accessToken);

  const [form, setForm] = useState(DEF_FORM(filters));
  const [categories, setCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date() - 1);
  const today = new Date();

  const onChange = (name = "", value = "") => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleClear = () => {
    setFilters({});
    setForm(DEF_FORM(null));
    setShow(false);
  };

  const handleSubmit = () => {
    const { amountRangeStart, amountRangeEnd, startDateRange, endDateRange } =
      form;

    if (amountRangeStart && !amountRangeEnd) {
      return RenderToast({
        message: "End price is required when start price is provided.",
        type: "warn",
      });
    }

    if (
      amountRangeStart &&
      amountRangeEnd &&
      Number(amountRangeStart) >= Number(amountRangeEnd)
    ) {
      return RenderToast({
        message: "Start price must be less than end price.",
        type: "warn",
      });
    }

    if (
      (startDateRange && !endDateRange) ||
      (endDateRange && !startDateRange)
    ) {
      return RenderToast({
        message: "Date is required in form of range.",
        type: "warn",
      });
    }

    if (
      startDateRange &&
      endDateRange &&
      new Date(startDateRange) > new Date(endDateRange)
    ) {
      return RenderToast({
        message: "Start date must be less than end date.",
        type: "warn",
      });
    }

    const filters = {
      ...(form.status?.value && { status: form.status?.value }),
      ...(form.RequestStatusId?.value && {
        RequestStatusId: form.RequestStatusId?.value,
      }),
      ...(form.RatingStatusId?.value && {
        RatingStatusId: form.RatingStatusId?.value,
      }),
      ...(form.JobStatusId?.value && {
        JobStatusId: form.JobStatusId?.value,
      }),
      ...(form.ServiceCategoryId?.id && {
        ServiceCategoryId: form.ServiceCategoryId?.id,
      }),
      ...(form.amountRangeStart && { amountRangeStart: form.amountRangeStart }),
      ...(form.amountRangeEnd && { amountRangeEnd: form.amountRangeEnd }),
      ...(form.Date && { Date: form.Date }),
      ...(form.startDateRange && { startDateRange: form.startDateRange }),
      ...(form.endDateRange && { endDateRange: form.endDateRange }),
    };

    setFilters(filters);
    setShow(false);
  };

  const getData = async () => {
    setLoadingCategories(true);

    const response = await Get(baseURL(`ServiceCategorys`), accessToken, false);

    if (response) {
      const data = response?.data;
      setCategories(data?.data);

      if (filters?.ServiceCategoryId) {
        setForm((prev) => ({
          ...prev,
          ServiceCategoryId:
            data?.data?.find(
              (item) => item?.id === filters?.ServiceCategoryId
            ) || null,
        }));
      }
    }

    setLoadingCategories(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <ModalSkeleton
      setShow={setShow}
      show={show}
      width={"600px"}
      header={"Filters"}
      showCloseIcon
    >
      <Row className="mb-3">
        {showStatusFilter && (
          <Col md={12} className="mb-3">
            <DropDown
              label="Status"
              placeholder="Status"
              options={STATUS}
              value={form.status}
              setter={(e) => onChange("status", e)}
            />
          </Col>
        )}

        {showRequestStatusFilter && (
          <Col md={12} className="mb-3">
            <DropDown
              label="Status"
              placeholder="Status"
              options={REQ_STATUS}
              value={form.RequestStatusId}
              setter={(e) => onChange("RequestStatusId", e)}
            />
          </Col>
        )}

        {showJobFilter && (
          <Col md={12} className="mb-3">
            <DropDown
              label="Status"
              placeholder="Status"
              options={JOB_STATUS}
              value={form.JobStatusId}
              setter={(e) => onChange("JobStatusId", e)}
            />
          </Col>
        )}

        {showRatingFilter && (
          <Col md={12} className="mb-3">
            <DropDown
              label="Rating"
              placeholder="Rating"
              options={RATING_STATUS}
              value={form.RatingStatusId}
              setter={(e) => onChange("RatingStatusId", e)}
            />
          </Col>
        )}

        {showServiceCategoryFilter && (
          <Col md={12} className="mb-3">
            <DropDown
              label="Category"
              placeholder={
                loadingCategories ? "Loading Categories..." : "Select"
              }
              options={categories}
              value={form.ServiceCategoryId}
              setter={(e) => onChange("ServiceCategoryId", e)}
              optionLabel="name"
            />
          </Col>
        )}

        {showAmountFilter && (
          <Col md={6} className="mb-3">
            <Input
              label="Minimum Amount"
              placeholder="Amount"
              type="number"
              value={form.amountRangeStart}
              setter={(e) => onChange("amountRangeStart", e)}
            />
          </Col>
        )}

        {showAmountFilter && (
          <Col md={6} className="mb-3">
            <Input
              label="Max Amount"
              placeholder="Amount"
              type="number"
              value={form.amountRangeEnd}
              setter={(e) => onChange("amountRangeEnd", e)}
            />
          </Col>
        )}

        {showStartDateFilter && (
          <Col md={6} className="mb-3">
            <DateInput
              label="Start Date"
              placeholder="Start Date"
              maxDate={today.setDate(today.getDate() - 1)}
              value={form.startDateRange}
              setValue={(e) => onChange("startDateRange", e)}
            />
          </Col>
        )}

        {showEndDateFilter && (
          <Col md={6} className="mb-3">
            <DateInput
              label="End Date"
              minDate={form.startDateRange}
              maxDate={today.setDate(today.getDate())}
              placeholder="End Date"
              value={form.endDateRange}
              setValue={(e) => onChange("endDateRange", e)}
            />
          </Col>
        )}

        {showDateFilter && (
          <Col md={12} className="mb-4">
            <DateInput
              label="Date"
              placeholder="Date"
              value={form.Date}
              setter={(e) => onChange("Date", e)}
            />
          </Col>
        )}

        <Col md={6}>
          <Button label="Clear" onClick={handleClear} className="w-100" />
        </Col>

        <Col md={6}>
          <Button
            variant="secondary"
            label="Apply"
            onClick={handleSubmit}
            className="w-100"
          />
        </Col>
      </Row>
    </ModalSkeleton>
  );
};

export default FiltersModal;

export const STATUS = [
  { label: "Enabled", value: "true" },
  { label: "Disabled", value: "false" },
];

export const REQ_STATUS = [
  { label: "Approved", value: "1" },
  { label: "Pending", value: "2" },
  { label: "Rejected", value: "3" },
];

export const JOB_STATUS = [
  { label: "All", value: "" },
  { label: "In Progress", value: "2" },
  { label: "Completed", value: "5" },
];

export const RATING_STATUS = [
  { label: "0", value: 0 },
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
];

const DEF_FORM = (filters = null) => {
  return {
    status: STATUS?.find((item) => item.value === filters?.status) || null,
    RequestStatusId:
      REQ_STATUS?.find((item) => item.value === filters?.RequestStatusId) ||
      null,
    JobStatusId:
      JOB_STATUS?.find((item) => item.value === filters?.JobStatusId) || null,
    RatingStatusId:
      RATING_STATUS?.find((item) => item.value === filters?.RatingStatusId) ||
      null,
    amountRangeStart: filters?.amountRangeStart || null,
    amountRangeEnd: filters?.amountRangeEnd || null,
    Date: filters?.Date || null,
    startDateRange: filters?.startDateRange || null,
    endDateRange: filters?.endDateRange || null,
    ServiceCategoryId: null,
  };
};
