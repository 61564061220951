import { BiSearch } from "react-icons/bi";
import { Input } from "../Input/Input";

function SearchInput({
  value,
  setter,
  placeholder = "Search",
  customStyle,
  inputStyle,
  backgroundColor,
  iconColor = "var(--secondary-color)",
  ...props
}) {
  // TODO: Change according to design
  return (
    <Input
      setter={setter}
      value={value}
      customStyle={{
        height: "100%",
        width: "400px",
        padding: "2px",
        backgroundColor: backgroundColor,
        borderRadius: "0px",
        paddingRight: "40px",
        border: "1px solid var(--placeholder-color)",
        ...customStyle,
      }}
      inputStyle={{
        padding: "8px 14px",
        fontSize: "var(--fs-base)",
        border: "none",
        ...inputStyle,
      }}
      placeholder={placeholder}
      rightIcon={<BiSearch size={22} color={iconColor} />}
      {...props}
    />
  );
}

export default SearchInput;
