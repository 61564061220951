"use client";

import SearchInput from "../SearchInput";
import { ClickAwayListener } from "@mui/material";
import { Fragment, useState } from "react";
import { OverlayTrigger } from "react-bootstrap";
import { LuSettings2 } from "react-icons/lu";
import { Button } from "../Button/Button";
import TabsComponent from "../TabsComponent/TabsComponent";
import classes from "./SubHeader.module.css";
import DateInput from "../DateInput";
import FiltersModal from "../FiltersModal/FiltersModal";

export default function SubHeader({
  title = "",
  filters = {},
  tabs = [],
  showSearch = false,
  showDateInput = false,
  showTabs = false,
  showSingleFilter = false,
  showMultiFilter = false,
  showStatusFilter = false,
  showRequestStatusFilter = false,
  showAmountFilter = false,
  showDateFilter = false,
  showStartDateFilter = false,
  showEndDateFilter = false,
  showJobFilter = false,
  showRatingFilter = false,
  search = "",
  setSearch = () => {},
  date = "",
  setDate = () => {},
  ...props
}) {
  const [show, setShow] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  const renderFilters = () => {
    return (
      <div className={classes.overlayCont}>
        <ClickAwayListener onClickAway={() => setShow(false)}>
          <div>
            <OverlayTrigger
              trigger={["click"]}
              placement={"bottom-end"}
              overlay={
                <ul className={classes.overlay}>
                  {filters?.map((item, index) => (
                    <li
                      key={index}
                      className={[classes.overlayLink].join(" ")}
                      onClick={() => {}}
                    >
                      {item?.label}
                    </li>
                  ))}
                </ul>
              }
              show={show}
              onToggle={() => setShow(!show)}
            >
              <div>
                <Button
                  label={`filters ${
                    Object.keys(filters || {})?.length
                      ? Object.keys(filters || {})?.length
                      : ""
                  }`}
                  variant="white"
                  className={classes.filter}
                  rightIcon={<LuSettings2 size={18} />}
                />
              </div>
            </OverlayTrigger>
          </div>
        </ClickAwayListener>
      </div>
    );
  };

  return (
    <>
      <div className={classes.tabs}>
        {title || tabs?.toString() ? (
          <Fragment>
            {title && <h3>{title}</h3>}
            {showTabs && <TabsComponent tabs={tabs} {...props} />}
          </Fragment>
        ) : (
          <div></div>
        )}

        <div className={classes.input_div}>
          {showSingleFilter && renderFilters()}
          {showMultiFilter && (
            <Button
              label={`Filters ${
                Object.keys(filters || {})?.length
                  ? `(${Object.keys(filters || {})?.length})`
                  : ""
              }`}
              variant="white"
              rightIcon={<LuSettings2 size={18} />}
              onClick={() => setShowFilters(true)}
              className={classes.filterButton}
            />
          )}
          {showDateInput && (
            <DateInput
              value={date}
              setValue={(e) => setDate(new Date(e).toISOString())}
            />
          )}
          {showSearch && (
            <SearchInput
              value={search}
              setter={setSearch}
              customStyle={{ width: "280px" }}
            />
          )}
        </div>
      </div>
      {showFilters && (
        <FiltersModal
          show
          setShow={setShowFilters}
          showStatusFilter={showStatusFilter}
          showRequestStatusFilter={showRequestStatusFilter}
          showAmountFilter={showAmountFilter}
          showDateFilter={showDateFilter}
          showStartDateFilter={showStartDateFilter}
          showEndDateFilter={showEndDateFilter}
          showJobFilter={showJobFilter}
          showRatingFilter={showRatingFilter}
          filters={filters}
          {...props}
        />
      )}
    </>
  );
}
