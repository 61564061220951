import React, { useEffect, useState } from "react";
import classes from "./Categories.module.css";
import { Col, Row } from "react-bootstrap";
import Content from "../Content/Content";
import { useSelector } from "react-redux";
import { Get, Put } from "../../Axios/AxiosFunctions";
import { baseURL } from "../../config/apiUrl";
import { RECORDS_LIMIT } from "../../data/constants";
import LoadMore from "../LoadMore/LoadMore";
import CategoryCard from "../CategoryCard";
import EditCategoryModal from "../EditCategoryModal/EditCategoryModal";

export default function Categories({ slug = "" }) {
  const { accessToken } = useSelector((state) => state?.authReducer);

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [changeLoading, setChangeLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [indivisualCategory, setIndivisualCategory] = useState({});

  const getData = async ({ loadMore = false, PageNumber = 1 }) => {
    const query = {
      userId: slug,
      PageSize: RECORDS_LIMIT,
      PageNumber,
    };

    let queryParams = new URLSearchParams(query);
    queryParams = queryParams?.toString();

    setLoading(loadMore ? "loadMore" : "loading");

    const res = await Get(
      baseURL(`ProfessionalServiceCategoryCommission?${queryParams}`),
      accessToken,
      false
    );

    if (res) {
      const data = res?.data;
      setTotalPages(data?.totalPages);
      setData((prevData) => {
        const _data = data?.data || [];
        if (loadMore) return [...prevData, ..._data];
        return _data;
      });
    }

    setLoading("");
  };

  useEffect(() => {
    getData({});
  }, []);

  return (
    <Col>
      <Content
        {...{
          loading: loading === "loading",
          data,
          noDataText: "No Category Found",
        }}
      >
        <div>
          <Row>
            {data?.map((item, i) => (
              <Col md={6} key={i} className="mb-3">
                <CategoryCard
                  data={item}
                  isServiceCategory={true}
                  handleEdit={() => {
                    setModal(true);
                    setIndivisualCategory(item);
                  }}
                />
              </Col>
            ))}
          </Row>
          <LoadMore
            {...{
              page,
              totalPages,
              loading: loading === "loadMore",
              setPage: () => {
                const _page = page + 1;
                setPage(_page);
                getData({ loadMore: true, PageNumber: _page });
              },
            }}
          />
        </div>
      </Content>

      {modal && (
        <EditCategoryModal show={modal} setShow={setModal} setData={setData} indivisualCategory={indivisualCategory} isServiceCategory={true} userId={slug} getData={getData}/>
      )}
    </Col>
  );
}
