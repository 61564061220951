"use client";

import moment from "moment";
import { Accordion, Col, Row } from "react-bootstrap";
import { achievement } from "../../constant/imagePath";
import AchievementCard from "../AchievementCard/AchievementCard";
import RelatedSolution from "../RelatedSolution/RelatedSolution";
import "./accordian.css";
import classes from "./Accordian.module.css";
import Content from "../Content/Content";

const renderRelatedSol = (data) => (
  <Accordion.Item eventKey="0">
    <Accordion.Header>Related Solution</Accordion.Header>
    <Accordion.Body>
      <RelatedSolution data={data?.relatedSolution} />
    </Accordion.Body>
  </Accordion.Item>
);

const renderAchievements = (data) => (
  <Accordion.Item eventKey="1">
    <Accordion.Header>Achievement</Accordion.Header>
    <Accordion.Body>
      <Content data={data?.achievements} noDataText="No Achievement Found">
        <Row className="gy-4">
          {data?.achievements?.map((item) => (
            <Col md={4} lg={3}>
              <AchievementCard
                key={item?.id}
                title={item?.title}
                from={item?.fromYear}
                to={item?.toYear}
                description={item?.description}
                image={item?.imagePath === null ? achievement : item?.imagePath}
                isImage={true}
              />
            </Col>
          ))}
        </Row>
      </Content>
    </Accordion.Body>
  </Accordion.Item>
);

const renderClient = (data) => (
  <Accordion.Item eventKey="2">
    <Accordion.Header>Client Work</Accordion.Header>
    <Accordion.Body>
      <Content data={data?.clientWorks} noDataText="No Client Work Found">
        <Row className="gy-4">
          {data?.clientWorks?.map((item) => (
            <Col md={4} lg={3}>
              <AchievementCard
                key={item.id}
                title={item.title}
                date={item?.projectDate}
                description={item.description}
                image={item.image}
              />
            </Col>
          ))}
        </Row>
      </Content>
    </Accordion.Body>
  </Accordion.Item>
);

const renderEducation = (data) => (
  <Accordion.Item eventKey="3">
    <Accordion.Header>Education</Accordion.Header>
    <Accordion.Body>
      <div className={classes.eduAccordian}>
        <Content data={data?.educations} noDataText="No Eduction Found">
          <Row className="gy-2">
            {data?.educations?.map((item) => {
              return (
                <Col md={4} lg={3}>
                  <div id={item?.id} className={classes.educationBox}>
                    <span>
                      <p>
                        <strong>{item?.degree} </strong>
                      </p>
                      <p> {item?.university}</p>
                      <p>{moment(item?.completionDate).format("ll")}</p>
                    </span>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Content>
      </div>
    </Accordion.Body>
  </Accordion.Item>
);

function Accordian({ data }) {
  return (
    <Row>
      <Col lg={12}>
        <Accordion defaultActiveKey="0" flush key="">
          {renderRelatedSol(data)}
          {renderAchievements(data)}
          {renderClient(data)}
          {renderEducation(data)}
        </Accordion>
      </Col>
    </Row>
  );
}

export default Accordian;
