import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaPlus } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { Delete, Get } from "../../Axios/AxiosFunctions";
import { Button } from "../../Component/Button/Button";
import Content from "../../Component/Content/Content";
import Header from "../../Component/Header/Header";
import LoadMore from "../../Component/LoadMore/LoadMore";
import ProfileNameEmail from "../../Component/ProfileNameEmail/ProfileNameEmail";
import QuestionCard from "../../Component/QuestionCard/QuestionCard";
import { baseURL } from "../../config/apiUrl";
import { RECORDS_LIMIT } from "../../data/constants";
import AddQuestionModal from "../../modals/AddQuestionModal/AddQuestionModal";
import AreYouSureModal from "../../modals/AreYouSureModal";
import { apiHeader } from "../../utils/helper";
import classes from "./Questionnaire.module.css";

export default function Questionnaire() {
  const { accessToken } = useSelector((state) => state?.authReducer);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState("");
  const [show, setShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showDelete, setShowDelete] = useState(false);
  const [delLoading, setDelLoading] = useState(false);

  const getData = async ({ loadMore = false, PageNumber = 1 }) => {
    const query = {
      id: 1,
      PageSize: RECORDS_LIMIT,
      PageNumber,
    };

    let queryParams = new URLSearchParams(query);
    queryParams = queryParams?.toString();

    setLoading(loadMore ? "loadMore" : "loading");
    !loadMore && setPage(1);

    const response = await Get(
      baseURL(`Questionnaire?${queryParams}`),
      accessToken
    );

    if (response) {
      const data = response?.data;
      setTotalPages(data?.totalPages);

      loadMore
        ? setData((prev) => [...prev, ...data?.data])
        : setData((prev) => data?.data);
    }

    setLoading("");
  };

  const handleDelete = async () => {
    setDelLoading(true);

    const response = await Delete(
      baseURL(`Question?id=${selectedItem?.id}`),
      null,
      apiHeader(accessToken)
    );

    if (response) {
      setData((prev) => prev?.filter((item) => item?.id !== selectedItem?.id));
    }

    setDelLoading(false);
    setShowDelete(false);
  };

  useEffect(() => {
    getData({});
  }, []);

  return (
    <>
      <Header />
      <ProfileNameEmail />
      <Container className="globalContainer">
        <Content
          {...{
            loading: loading === "loading",
            data,
            noDataText: "No Questionnaire Found",
          }}
        >
          <div className={classes.heading}>
            <h5>Questionnaire</h5>
            <Button
              onClick={() => {
                setShow(true);
                setSelectedItem(null);
              }}
              rightIcon={<FaPlus size={20} />}
              variant="secondary"
              className={classes.btn}
            >
              Add Questions
            </Button>
          </div>
          <Row className="gy-4 mt-2">
            {data?.map((item, index) => {
              return (
                <Col lg={12} id={index}>
                  <QuestionCard
                    data={item}
                    onDelete={() => {
                      setShowDelete(true);
                      setSelectedItem(item);
                    }}
                    onEdit={() => {
                      setShow(true);
                      setSelectedItem(item);
                    }}
                  />
                </Col>
              );
            })}
          </Row>
        </Content>

        {loading !== "loading" && (
          <LoadMore
            {...{
              page,
              totalPages,
              loading: loading === "loadMore",
              setPage: () => {
                const _page = page + 1;
                setPage(_page);
                getData({ loadMore: true, PageNumber: _page });
              },
            }}
          />
        )}
      </Container>

      {showDelete && (
        <AreYouSureModal
          show
          setShow={setShowDelete}
          subTitle={"Are you sure you want to delete this question?"}
          onClick={handleDelete}
          loading={delLoading}
        />
      )}

      {show && (
        <AddQuestionModal
          show
          setShow={setShow}
          callback={() => getData({})}
          data={selectedItem}
        />
      )}
    </>
  );
}
