import moment from "moment";
import { FaArrowRightLong } from "react-icons/fa6";
import { MdOutlineAccountCircle, MdOutlineDateRange } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { getFormattedPrice } from "../../utils/helper";
import { Button } from "../Button/Button";
import Statuses from "../Status";
import styles from "./JobsCard.module.css";

const JobsCard = ({ jobs, jobView = false, client = false }) => {
  const navigate = useNavigate();

  const role = client ? "2" : "3";

  return (
    <div className={styles.service_cards_container}>
      <div className={styles.card_main_div}>
        {!jobView && (
          <>
            <Statuses status={jobs?.requestStatusName?.toLowerCase()} />
            <hr />
          </>
        )}

        <div className={styles.user_div}>
          <div>
            <MdOutlineAccountCircle size={35} color="#36696B" />
          </div>
          <div>
            <h4>
              {role == "3"
                ? jobs?.requesterName || jobs?.clientName
                : jobs?.professionalName}
            </h4>
          </div>
        </div>
        <hr />
        <div className={styles.service_card}>
          <div className={styles.subDiv}>
            <div>
              <p>{jobs?.categoryName}</p>
              <h5>{jobs?.serviceTitle}</h5>

              <p className="d-flex align-items-center gap-2 mt-3">
                <MdOutlineDateRange size={20} />
                <b
                  style={{
                    color: "#2F5456",
                    fontFamily: "var(--ff-primary-bold)",
                  }}
                >
                  {moment(jobs?.createdOn).format("YYYY-MM-DD")}
                </b>
              </p>
            </div>

            {(role == "3" ||
              (role == "2" && jobs?.requestStatusName === "approved")) && (
              <div className={styles.pricingDiv}>
                <p>Pricing</p>
                <h5>{getFormattedPrice(jobs?.price || jobs?.amount)}</h5>
              </div>
            )}
          </div>

          <div>
            <Button
              onClick={() => navigate(`/jobs/${jobs?.id}`)}
              className={styles.btn}
              label={"View Details"}
              rightIcon={<FaArrowRightLong size={18} />}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobsCard;
