import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Get } from "../../Axios/AxiosFunctions";
import { Loader } from "../../Component/Loader";
import NoData from "../../Component/NoData/NoData";
import PaginationComponent from "../../Component/PaginationComponent";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import { baseURL, mediaUrl } from "../../config/apiUrl";
import { DemoProfile } from "../../constant/imagePath";
import classes from "./Notifications.module.css";
import { RECORDS_LIMIT } from "../../data/constants";

const NotificationBox = ({ item }) => {
  const navigate = useNavigate();
  const notificationImage = item?.orderId?.items[0]?.productId?.images[0]
    ? mediaUrl(item?.orderId?.items[0]?.productId?.images[0])
    : DemoProfile;
  return (
    <>
      <div
        className={[classes.HeaderNotificationInnerDiv]}
        onClick={() => {
          if (item?.flag === "purchase-screen") {
            navigate(`/order-detail/${item?.orderId?.slug}`);
          }
        }}
      >
        <div className={[classes.ImgDiv]}>
          <img src={notificationImage} alt="product image" />
        </div>
        <p>{item?.message}</p>

        <span className={classes?.timeText}>
          {moment(item?.createdAt).fromNow()}
        </span>
        {!item?.seen && <span className={classes?.notSeen}>🔴</span>}
      </div>
    </>
  );
};

const Notifications = () => {
  const { accessToken: accessToken } = useSelector(
    (state) => state?.authReducer
  );
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);

  async function getData(pg = page) {
    const url = baseURL(`notification/all?page=${pg}&limit=${RECORDS_LIMIT}`);
    setLoading(true);
    const response = await Get(url, accessToken);
    if (response) {
      setData(response?.data?.data);
      setTotalResults(response?.data?.totalCount);
    }
    setLoading(false);
  }

  useEffect(() => {
    getData(page);
  }, [page]);

  return (
    <>
      <div>
        <SideBarSkeleton>
          <div className={classes.content}>
            <Container className={classes.main}>
              <Row className={classes.row}>
                <Col md={12}>
                  <div className={classes.spBtw}>
                    <h6>Notifications</h6>
                  </div>
                </Col>
                <Col md={12}>
                  <div className={classes.notificationContainer}>
                    {loading ? (
                      <Loader className={classes.loader} />
                    ) : data?.length == 0 ? (
                      <NoData
                        text="No Notifications Found"
                        className={classes.loader}
                      />
                    ) : (
                      data?.map((item) => <NotificationBox item={item} />)
                    )}
                  </div>
                </Col>
                <Col md={12}>
                  <div className={classes.paginationDiv}>
                    {!!data?.length && (
                      <PaginationComponent
                        totalPages={Math.ceil(totalResults / RECORDS_LIMIT)}
                        setCurrentPage={setPage}
                        currentPage={page}
                      />
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </SideBarSkeleton>
      </div>
    </>
  );
};
export default Notifications;
