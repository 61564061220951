"use client";

import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Get } from "../../Axios/AxiosFunctions";
import Content from "../../Component/Content/Content";
import LoadMore from "../../Component/LoadMore/LoadMore";
import SubHeader from "../../Component/SubHeader/SubHeader";
import { baseURL } from "../../config/apiUrl";
import { RECORDS_LIMIT } from "../../data/constants";
import Header from "../../Component/Header/Header";
import ProfileNameEmail from "../../Component/ProfileNameEmail/ProfileNameEmail";
import { SUPPORT_TABS } from "../../data/app-data";
import classes from "./SupportPage.module.css";
import SupportCard from "../../Component/SupportCard/SupportCard";
import { useSelector } from "react-redux";

export default function SupportPage() {
  const { accessToken } = useSelector((state) => state.authReducer);

  const [tab, setTab] = useState(SUPPORT_TABS[0]?.value);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState("");

  const getData = async ({
    loadMore = false,
    PageNumber = 1,
  }) => {
    setLoading("loading");
    let ticketStatusIdValue = "";
    if(tab == "pending") ticketStatusIdValue = 2;
    if (tab == "resolved") ticketStatusIdValue = 1;

    const query = {
      PageSize: RECORDS_LIMIT,
      PageNumber,
      ticketStatusId : ticketStatusIdValue
    };

    let queryParams = new URLSearchParams(query);
    queryParams = queryParams?.toString();

    setLoading(loadMore ? "loadMore" : "loading");

    const response = await Get(
      baseURL(`SupportTickets?${queryParams}`),
      accessToken,
      false
    );

    if (response) {
      const data = response?.data;
      setTotalPages(data?.totalPages);
      setData((prev) => {
        const _data = data?.data || [];
        if (loadMore) return [...prev, ..._data];
        return _data;
      });
    }

    setLoading("");
  };

useEffect(() => {
    setPage(1);
    getData({})
  }, [tab]);

  return (
    <>
      <Header />
      <ProfileNameEmail />

      <Container className={classes.container}>
        <h3 className={classes.title}>Support</h3>
        <SubHeader
          title=""
          showSearch={false}
          showTabs={true}
          tab={tab}
          setTab={setTab}
          tabs={SUPPORT_TABS}
        />
        <Content
          {...{
          data,
          noDataText: "No Support Requests Found",
          loading: loading === "loading",
        }}
        >
          <Row>
            {data.map((support, index) => (
              <Col xs={12} key={index}>
              <SupportCard data={support} getData={getData}/>
              </Col>
            ))}
          </Row>

          <LoadMore
          {...{
            page,
            totalPages,
            loading: loading === "loadMore",
            setPage: () => {
              const _page = page + 1;
              setPage(_page);
              getData({ loadMore: true, PageNumber: _page });
            },
          }}
        />
        </Content>
      </Container>
    </>
  );
}
