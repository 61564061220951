import axios from "axios";
import RenderToast from "../Component/RenderToast/";
import { CURRENCY_SYMBOL } from "../data/constants";
import { FORM_REG_EX, FORM_REG_EX_REPLACER } from "../data/regex";
import moment from "moment";

export const getFormattedPrice = (price = 0, symbol = true) => {
  return `${symbol ? CURRENCY_SYMBOL : ""} ${parseFloat(price).toFixed(2)}`;
};

export const getNA = (item = null) => {
  return item || "N/A";
};

export const mergeClass = (...classes) => {
  return classes.join(" ");
};

export const capitalizeFirstLetter = (l) =>
  l.charAt(0).toUpperCase() + l.slice(1);

export const getFormattedParams = (param) =>
  capitalizeFirstLetter(param?.replace(FORM_REG_EX, FORM_REG_EX_REPLACER));

export const formatNumber = (num) => {
  if (num >= 1000) {
    var suffixes = ["", "k", "m", "b", "t"];
    var suffixNum = Math.floor(("" + num).length / 3);
    var shortValue = parseFloat(
      (suffixNum !== 0 ? num / Math.pow(1000, suffixNum) : num).toPrecision(2)
    );
    if (shortValue % 1 !== 0) {
      shortValue = shortValue.toFixed(1);
    }
    return shortValue + suffixes[suffixNum];
  }
  return num;
};

export function numberWithCommas(x = 0) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const createFormData = (data) => {
  const formData = new FormData();
  for (let key in data) {
    if (Array.isArray(data[key])) {
      for (let d in data[key]) {
        if (typeof data[key][d] == "string") {
          formData.append(key, data[key][d]);
        } else if (
          data[key][d] instanceof File ||
          data[key][d] instanceof Date
        ) {
          formData.append(key, data[key][d]);
        } else {
          formData.append(key, JSON.stringify(data[key][d]));
        }
      }
    } else if (typeof data[key] == "object") {
      if (data[key] instanceof File) {
        formData.append(key, data[key]);
      } else {
        formData.append(key, JSON.stringify(data[key]));
      }
    } else {
      formData.append(key, data[key]);
    }
  }
  return formData;
};

export const apiHeader = (token, isFormData) => {
  if (token && !isFormData) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
  }
  if (token && isFormData) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
  }
  if (!token && !isFormData) {
    return {
      headers: {
        "Content-Type": "application/json",
      },
    };
  }

  if (!token && isFormData) {
    return {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
  }
};

export const makeWordPlural = (word = "", len = 0, concat = "s") => {
  return `${word}${len > 1 ? concat : ""}`;
};

export const uploadToPresignedUrl = async (
  presignedUrl,
  selectedFile,
  setUploadProgress
) => {
  try {
    return await axios.put(presignedUrl, selectedFile, {
      headers: {
        "Content-Type": selectedFile.type,
      },

      onUploadProgress: (data) => {
        const progress = Math.round((100 * data.loaded) / data.total);
        setUploadProgress((prev) => (prev >= progress ? prev : progress));
      },
    });
  } catch (error) {
    return null;
  }
};

// Output: 00:00
export const formatMinutes = (minutes = 0) => {
  const hours = Math.floor(minutes / 60);

  const remainingMinutes = minutes % 60;

  const formattedHours = hours < 10 ? "0" + hours : hours;
  const formattedMinutes =
    remainingMinutes < 10 ? "0" + remainingMinutes : remainingMinutes;

  const time24Hour = formattedHours + ":" + formattedMinutes;

  return time24Hour;
};

// Output: 0 hr 0 mints
export const formatMinutes2 = (item = null) => {
  const topicDuration = item?.topics?.reduce(
    (acc, topic) => acc + topic.duration,
    0
  );
  if (topicDuration > 59) {
    const hours = Math.floor(topicDuration / 60);
    const minutes = topicDuration % 60;
    return `${hours} hr ${minutes} min`;
  } else {
    return `${topicDuration} minutes`;
  }
};

export const formatMinutes3 = (topicDuration = 0) => {
  if (topicDuration > 59) {
    const hours = Math.floor(topicDuration / 60);
    const minutes = topicDuration % 60;
    if (minutes > 0)
      return `${hours} ${makeWordPlural(
        "hour",
        hours
      )} ${minutes} ${makeWordPlural("minute", minutes)}`;
    else return `${hours} ${makeWordPlural("hour", hours)}`;
  } else {
    return `${topicDuration} ${makeWordPlural("minute", topicDuration)}`;
  }
};

export const formatMinutes4 = (topicDuration = 0) => {
  if (topicDuration > 59) {
    const hours = Math.floor(topicDuration / 60);
    const minutes = topicDuration % 60;
    return `${hours} hr ${minutes} min`;
  } else {
    return `${topicDuration} minutes`;
  }
};

export async function FileDownloadFromUrl(
  url,
  fileName,
  token = "",
  setLoading = () => {}
) {
  setLoading(true);
  axios({
    url: url,
    responseType: "blob",
    method: "GET",
    ...apiHeader(token),
  })
    .then((res) => {
      const href = URL.createObjectURL(res.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", fileName); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      setLoading(false);
    })
    .catch((e) => {
      setLoading(false);
      RenderToast({
        type: "error",
        message: `No Data for Downloading.`,
      });
    });
}

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export function getInitials(fullName) {
  const nameParts = fullName.split(" ");
  const initials = nameParts
    .map((part) => part.charAt(0).toUpperCase())
    .join("")
    ?.slice(0, 2);
  return initials;
}

export const getLocalTime = (date = "") => {
  return moment(moment.utc(date).format()).fromNow();
};

export const getNestedObject = (obj = {}, nestedKey = "") => {
  const keys = nestedKey.split(".");
  const value = keys.reduce((acc, currKey) => acc && acc[currKey], obj);
  return value;
};

export function parseJwt(token) {
  if (!token) {
    return;
  }
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
}

export function timeAgo(timestamp) {
  const now = new Date();
  const then = new Date(timestamp);
  const diff = now - then; // difference in milliseconds

  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else {
    return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
  }
}
