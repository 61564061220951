import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import React, { Suspense, lazy, useEffect, useRef } from "react";
import { Beforeunload } from "react-beforeunload";
import "react-clock/dist/Clock.css";
import "react-modern-drawer/dist/index.css";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { Get } from "./Axios/AxiosFunctions";
import { Loader } from "./Component/Loader";
import BeforeLoginRoute from "./Helper/BeforeLoginRoute";
import ProtectedRouter from "./Helper/ProtectedRoute";
import "./assets/Styles/style.css";
import "./assets/Styles/table.css";
import { API_URL, baseURL } from "./config/apiUrl";
import CreateSubAdmin from "./pages/CreateSubAdmin/CreateSubAdmin";
import Notifications from "./pages/Notifications";
import ProfessionalsDetail from "./pages/ProfessionalsDetail/ProfessionalsDetail";
import ProfileSetting from "./pages/ProfileSetting";
import SubAdmin from "./pages/SubAdmin/SubAdmin";
import UserDetail from "./pages/UserDetail/UserDetail";
import { updateUser } from "./store/auth/authSlice";
import {
  saveChatCounter,
  saveNotiData,
  setNotificationCount,
} from "./store/common/commonSlice";
import Questionnaire from "./pages/Questionnaire/Questionnaire";
import AddQuestion from "./pages/Questionnaire/AddQuestion/AddQuestion";
import SingleChatPage from "./pages/SingleChatPage/SingleChatPage";
import SubscriptionView from "./pages/Subscriptions/SubscriptionView";
import SupportPage from "./pages/SupportPage/SupportPage";
import NotificationsView from "./pages/NotificationsView/NotificationsView";
import * as signalR from "@microsoft/signalr";

const Login = lazy(() => import("./pages/Login"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Wallet = lazy(() => import("./pages/Wallet/Wallet"));
const Request = lazy(() => import("./pages/Request/Request"));
const Category = lazy(() => import("./pages/Category/Category"));
const Users = lazy(() => import("./pages/Users/Users"));
const JobDetails = lazy(() => import("./pages/JobDetails/JobDetails"));
const AddCategory = lazy(() =>
  import("./pages/Category/AddCategory/AddCategory")
);
const NotFound = lazy(() => import("./pages/NotFound"));
const Professional = lazy(() => import("./pages/Professionals/Professionals"));
const Rewards = lazy(() => import("./pages/Rewards/Rewards"));
const ResetPassword = lazy(() => import("./pages/ResetPasswordPage"));
const SessionDetail = lazy(() => import("./pages/SessisonDetailPage"));
const ServicesDetail = lazy(() => import("./pages/ServicesDetail"));
const ServicesCommissionPage = lazy(() =>
  import("./pages/ServicesDetail/EditCommission")
);
const CreateReward = lazy(() => import("./pages/CreateReward/CreateReward"));
const ManageZoom = lazy(() => import("./pages/ManageZoom/ManageZoom"));

export let socket = null;
function App() {
  const { user, isLogin, accessToken, notificationCount } = useSelector(
    (state) => state?.authReducer
  );
  const userDataState = useSelector((state) => state?.authReducer?.user);
  const dispatch = useDispatch();
  const connection = useRef(null);
  console.log("✅ New Changes: Client Feedback, New2");

  const getMe = async () => {
    const url = baseURL("User");
    const response = await Get(url, accessToken);

    if (response) {
      const user = response?.data?.data;
      dispatch(updateUser(user));
    }
  };

  useEffect(() => {
    if (isLogin) {
      // socket = io(API_URL, {
      //   transports: ["websocket"],
      //   reconnection: true,
      //   reconnectionDelay: 180000,
      //   reconnectionDelayMax: 300000,
      // });
      // socket?.emit("join", userDataState?._id);
      getMe();
    }
  }, [isLogin]);

  useEffect(() => {
    socket?.on("new-notification", (notification, number) => {
      //something went wrong here
      if (
        notification?.receiver == userDataState?._id &&
        window.location.pathname !== "/notifications" &&
        notification?.flag == "project"
      ) {
        dispatch(saveNotiData());
      }
      //something went wrong here

      if (
        notification?.receiver == userDataState?._id &&
        notification?.flag == "chat" &&
        window.location.pathname !== "/chat"
      ) {
        dispatch(saveChatCounter(notification));
        dispatch(saveNotiData());
      }
    });
    return () => {
      socket?.off("new-notification");
    };
  }, []);

  useEffect(() => {
    if (!isLogin) return;

    connection.current = new signalR.HubConnectionBuilder()
      .withUrl(`${API_URL}/notificationHub`, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .build();

    connection.current
      .start()
      .then(() => {
        console.log("SignalR connected");

        connection.current.on(`notification_${user?.id}`, (notification) => {
          dispatch(setNotificationCount(notificationCount + 1));
          console.log(
            "🚀 ~ connection.current.on ~ notification123:",
            notification
          );
        });
      })
      .catch((err) => console.error("Connection error:", err.toString()));

    // Cleanup connection and listener on component unmount or `isLoggedIn` change
    return () => {
      connection.current
        .stop()
        .then(() => console.log("SignalR disconnected"))
        .catch((err) => console.error("Disconnect error:", err.toString()));
    };
  }, [isLogin]);

  return (
    <>
      <ToastContainer />
      <Beforeunload
        onBeforeunload={() => {
          if (isLogin) {
            socket?.emit("disconnected", user?._id);
          }
        }}
      />
      <BrowserRouter>
        <Suspense fallback={<Loader className={"mvh-100"} />}>
          <Routes>
            <Route
              path="/"
              exact
              element={<BeforeLoginRoute element={<Login />} />}
            />

            {/* <Route
              path="/forgot-password"
              exact
              element={<BeforeLoginRoute element={<ForgetPasswordPage />} />}
            />
            <Route
              path="/verify-otp"
              exact
              element={<BeforeLoginRoute element={<VerifyOTP />} />}
            /> */}
            {/* <Route
              path="/reset-password"
              exact
              element={<BeforeLoginRoute element={<SetNewPassword />} />}
            /> */}
            {/* ------------------ After Login ------------------- */}
            <Route
              path="/dashboard"
              exact
              element={<ProtectedRouter element={<Dashboard />} />}
            />
            <Route
              exact
              path="/services/:id"
              element={<ProtectedRouter element={<ServicesDetail />} />}
            />
            <Route
              exact
              path="/services/detail/edit/commission/:id"
              element={<ProtectedRouter element={<ServicesCommissionPage />} />}
            />
            <Route
              path="/professionals"
              exact
              element={<ProtectedRouter element={<Professional />} />}
            />
            <Route
              path="/auth/reset-password"
              exact
              // element={<ProtectedRouter element={<ResetPassword />} />}
              element={<ResetPassword />}
            />
            <Route
              path="/professionals/:slug"
              exact
              element={<ProtectedRouter element={<ProfessionalsDetail />} />}
            />
            <Route
              path="/notifications"
              exact
              element={<ProtectedRouter element={<NotificationsView />} />}
            />
            <Route
              path="/sub-admins"
              exact
              element={<ProtectedRouter element={<SubAdmin />} />}
            />
            <Route
              path="/session/detail/:id"
              element={<ProtectedRouter element={<SessionDetail />} />}
            />
            <Route
              path="/create-sub-admin"
              exact
              element={<ProtectedRouter element={<CreateSubAdmin />} />}
            />
            <Route
              path="/notifications"
              exact
              element={<ProtectedRouter element={<Notifications />} />}
            />
            <Route
              path="/users/:slug"
              exact
              element={<ProtectedRouter element={<UserDetail />} />}
            />
            <Route
              path="/wallet"
              exact
              element={<ProtectedRouter element={<Wallet />} />}
            />
            <Route
              path="/requests"
              exact
              element={<ProtectedRouter element={<Request />} />}
            />
            <Route
              path="/professional-request-chat"
              exact
              element={<ProtectedRouter element={<SingleChatPage />} />}
            />
            <Route
              path="/support-chat"
              exact
              element={<ProtectedRouter element={<SingleChatPage />} />}
            />
            <Route
              path="/category"
              exact
              element={<ProtectedRouter element={<Category />} />}
            />
            <Route
              path="/users"
              exact
              element={<ProtectedRouter element={<Users />} />}
            />
            <Route
              path="/jobs/:slug"
              exact
              element={<ProtectedRouter element={<JobDetails />} />}
            />
            <Route
              path="/addcategory"
              exact
              element={<ProtectedRouter element={<AddCategory />} />}
            />
            <Route
              path="/settings"
              exact
              element={<ProtectedRouter element={<ProfileSetting />} />}
            />
            <Route
              path="/rewards"
              exact
              element={<ProtectedRouter element={<Rewards />} />}
            />
            <Route
              path="/rewards/create-reward"
              exact
              element={<ProtectedRouter element={<CreateReward />} />}
            />
            <Route
              path="/questionnaire"
              exact
              element={<ProtectedRouter element={<Questionnaire />} />}
            />
            <Route
              path="/addquestion"
              exact
              element={<ProtectedRouter element={<AddQuestion />} />}
            />
            <Route
              path="/subscriptions"
              exact
              element={<ProtectedRouter element={<SubscriptionView />} />}
            />
            <Route
              path="/support"
              exact
              element={<ProtectedRouter element={<SupportPage />} />}
            />
            <Route
              path="/manage-zoom"
              exact
              element={<ProtectedRouter element={<ManageZoom />} />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;

console.log("Deployment....");
