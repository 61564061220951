import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Post } from "../../Axios/AxiosFunctions";
import { Button } from "../../Component/Button/Button";
import { Input } from "../../Component/Input/Input";
import RenderToast from "../../Component/RenderToast";
import { baseURL } from "../../config/apiUrl";
import { apiHeader } from "../../utils/helper";
import ModalSkeleton from "../ModalSkeleton";
import styles from "./UpdatePassword.module.css";
const UpdatePasswordModal = ({ show, setShow }) => {
  const dispatch = useDispatch();

  const inputStyle = {};
  const { accessToken } = useSelector((state) => state.authReducer);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdate = async () => {
    const params = {
      passwordCurrent: currentPassword,
      password: newPassword,
      passwordConfirm: confirmPassword,
    };
    // validate
    for (let key in params)
      if (params[key] === "")
        return RenderToast({
          type: "error",
          message: `Fields can not be empty.`,
        });

    // validate password
    if (newPassword.length < 8)
      return RenderToast({
        type: "warn",
        message: `Password must be at least 8 characters long.`,
      });
    if (newPassword !== confirmPassword)
      return RenderToast({
        type: "warn",
        message: `Password and confirm password do not match.`,
      });

    // call api
    const url = baseURL(`User/changepassword`);

    setIsLoading(true);
    const response = await Post(
      url,
      { oldPassword: currentPassword, newPassword },
      apiHeader(accessToken)
    );

    if (response) {
      RenderToast({
        type: "success",
        message: `Your Password Successfully Updated`,
      });
    }

    setShow(false);
    setIsLoading(false);
  };
  return (
    <ModalSkeleton
      setShow={setShow}
      show={show}
      width={"600px"}
      header={"Update Password"}
      borderRadius={"20px"}
      showCloseIcon
    >
      <Row className={styles.row}>
        <Col md={12}>
          <Input
            setter={setCurrentPassword}
            value={currentPassword}
            placeholder={"Enter Current Password"}
            label={"Current Password"}
            type={"password"}
            customStyle={inputStyle}
          />
        </Col>
        <Col md={12}>
          <Input
            setter={setNewPassword}
            value={newPassword}
            placeholder={"Enter New password"}
            label={"New Password"}
            type={"password"}
            customStyle={inputStyle}
          />
        </Col>
        <Col md={12}>
          <Input
            setter={setConfirmPassword}
            value={confirmPassword}
            placeholder={"Enter Confirm password"}
            label={"Confirm Password"}
            type={"password"}
            customStyle={inputStyle}
          />
        </Col>

        <Col md={12} className={styles.signUpBtnWrap}>
          <Button
            label={isLoading ? "Updating" : "Update Password"}
            className={styles.UpdateBtn}
            variant="secondary"
            onClick={handleUpdate}
          />
        </Col>
      </Row>
    </ModalSkeleton>
  );
};

export default UpdatePasswordModal;
