import { AiOutlinePercentage } from "react-icons/ai";
import { MdOutlineAttachment } from "react-icons/md";

export const PROF = [
  {
    id: "",
    userId: "25",
    emailAccount: "No",
    stripAccount: "No",
    professionalApproval: "No",
    clientApproval: "No",
    createdBy: "25",
    createdOn: new Date().toISOString(),
    updatedBy: "N/A",
    UpdatedOn: new Date().toISOString(),
  },
];

export const PROFESSIONAL_SERVICE_TAB_CARD = {
  title: "Estate Planning",
  subtitle: "Property Estimation",
  userName: "Karin Bezuidenhout",
  label: "Status",
  status: "In Progress",
  requests: 122,
  price: "$36",
};

export const CHATTING_PAGE_DATA = {
  userName: "Janie Friedman",

  chattingMessages: {
    receivedBy: "Janie Friedman",
    sentBy: "You",
    sentMessage: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    receivedMessage: "Excepteur sint occaecat cupidatat non",
    sentTime: "9:27 AM",
    receivedTime: "9:28 AM",
  },
};

export const CHATTING_ROOM_DATA = {
  userName: "Janie Friedman",
  time: 4,
};

export const REFERRALS_TAB_DATA = {
  name: "Karin Bezuidenhout",
  role: "Professional",
  time: "June, 2024",
};

export const NOTIFICATION_DATA = [
  {
    id: 1,
    image: "/images/Avatar.png",
    name: "Oscar",
    email: "oscar@email.com",
  },
  {
    id: 2,
    image: "/images/Avatar.png",
    name: "Daniel",
    email: "daniel@email.com",
  },
  {
    id: 3,
    image: "/images/Avatar.png",
    name: "Mark",
    email: "mark@email.com",
  },
];

export const REFERRALS_STATS_DATA = [
  {
    icon: <MdOutlineAttachment />,
    title: "Total Referrals",
    number: "14",
  },
  {
    icon: <AiOutlinePercentage />,
    title: "Total Discount",
    number: "10%",
  },
];

export const REVIEWS_TAB_DATA = {
  userName: "Karin Bezuidenhout",
  subTitle: "Estate Planning",
  title: "Property Estimation",
  time: 4,
  description:
    "Figma ipsum component variant main layer. Background arrow hand fill overflow scale team pen layout group. Vector reesizing rotate rotate group. Plugin frame create scale project library. Italic plugin rectangle group clip strikethrough prototype mask auto subtract. Fill opacity team share rotate layer blur scale polygon union. Layer reesizing scale layout union distribute. Pixel polygon main font hand mask opacity frame style edit. Bullet shadow align bold auto slice. Content edit overflow bold text draft flows line horizontal effect. ",
  rating: 4.4,
};

export const USER_DETAIL_DATA = {
  firstName: "M. Craig",
  lastName: " G. Brown",

  description:
    "Supporting Canada’s dynamic emerging technology companies requires an intimate understanding of the business, finance and legal challenges they will face along the entire life cycle of their businesses. Craig is a senior practitioner with that valued capability.",
  phone: "+1 416 868 3411",
  email: "cbrown@fasken.com",
};

export const SESSIONS_TAB_DATA = {
  session: "One-to-One call session",
  subTitle: "Property Estimation",
  date: "Jun 25, 2024",
  time: "07:00 PM",
  price: "$36",
};

export const REQUESTS_CARD_DATA = {
  status: "Pending",
  userName: "Oscar",
  email: "email@oscarfakedomain.net",
  date: "Jun 28, 2024",
  time: "8:00 pm",
  topic: "Property Estimation",
  postedTime: 4,
};

export const TRANSACTION_DATA = [
  {
    id: 1,
    user_name: "Karin Bezuidenhout",
    title: "Estate Planning",
    subtitle: "Property Estimation",
    date: "Aug, 2023",
    price: "$36",
    session: "One-to-One Session",
  },
  {
    id: 2,
    user_name: "Karin Bezuidenhout",
    title: "Estate Planning",
    subtitle: "Property Estimation",
    date: "Aug, 2023",
    price: "$36",
    session: "One-to-One Session",
  },
  {
    id: 3,
    user_name: "Karin Bezuidenhout",
    title: "Estate Planning",
    subtitle: "Property Estimation",
    date: "Aug, 2023",
    price: "$36",
    session: "One-to-One Session",
  },
  {
    id: 4,
    user_name: "Karin Bezuidenhout",
    title: "Estate Planning",
    subtitle: "Property Estimation",
    date: "Aug, 2023",
    price: "$36",
    session: "One-to-One Session",
  },
  {
    id: 5,
    user_name: "Karin Bezuidenhout",
    title: "Estate Planning",
    subtitle: "Property Estimation",
    date: "Aug, 2023",
    price: "$36",
    session: "One-to-One Session",
  },
  {
    id: 6,
    user_name: "Karin Bezuidenhout",
    title: "Estate Planning",
    subtitle: "Property Estimation",
    date: "Aug, 2023",
    price: "$36",
    session: "One-to-One Session",
  },
];

export const WALLET_TABS = [
  {
    label: "Service Payments",
    value: "service_payments",
  },
  {
    label: "Session Payments",
    value: "session_payments",
  },
  {
    label: "Cashback History",
    value: "cashback_history",
  },
];

export const USER_TABS = [
  {
    label: "Details",
    value: "details",
  },
  {
    label: "Jobs",
    value: "jobs",
  },
  {
    label: "Chats",
    value: "chats",
  },
  {
    label: "Sessions",
    value: "sessions",
  },
];

export const JOB_TABS = [
  {
    label: "Details",
    value: "details",
  },
  {
    label: "Chats",
    value: "chats",
  },
];

export const CATEGORY_DATA = [
  {
    key: 1,
    title: "Estate Planning",
    subtitle: "Commission",
    percent: "12%",
    date: "june 25, 2024",
  },
  {
    key: 2,
    title: "Estate Planning",
    subtitle: "Commission",
    percent: "12%",
    date: "june 25, 2024",
  },
  {
    key: 3,
    title: "Estate Planning",
    subtitle: "Commission",
    percent: "12%",
    date: "june 25, 2024",
  },
  {
    key: 4,
    title: "Estate Planning",
    subtitle: "Commission",
    percent: "12%",
    date: "june 25, 2024",
  },
  {
    key: 5,
    title: "Estate Planning",
    subtitle: "Commission",
    percent: "12%",
    date: "june 25, 2024",
  },
  {
    key: 6,
    title: "Estate Planning",
    subtitle: "Commission",
    percent: "12%",
    date: "june 25, 2024",
  },
  {
    key: 7,
    title: "Estate Planning",
    subtitle: "Commission",
    percent: "12%",
    date: "june 25, 2024",
  },
  {
    key: 8,
    title: "Estate Planning",
    subtitle: "Commission",
    percent: "12%",
    date: "june 25, 2024",
  },
];

export const REQUEST_DATA = [
  {
    key: 1,
    title: "Karin Bezuidenhout",
    desc: "wants to download session",
    data: "4 hrs ago",
  },
  {
    key: 2,
    title: "Karin Bezuidenhout",
    desc: "wants to download session",
    data: "4 hrs ago",
  },
  {
    key: 3,
    title: "Karin Bezuidenhout",
    desc: "wants to download session",
    data: "4 hrs ago",
  },
  {
    key: 4,
    title: "Karin Bezuidenhout",
    desc: "wants to download session",
    data: "4 hrs ago",
  },
];

export const STRIPE_CARD = {
  title: "Total Income",
  value: "71,897",
  percent: "122",
  iconimg: "images/logos_stripe.png",
  icontext: "Account Configured",
  label1: "Email:",
  label2: "Country",
  user: "clientname@example.com",
  country: "United States",
};

export const USER_DATA = [
  {
    key: 1,
    title: "M. Craig Brown",
    number: "+1 416 868 3143",
    email: "cbrown#@fasken.com",
  },
  {
    key: 2,
    title: "M. Craig Brown",
    number: "+1 416 868 3143",
    email: "cbrown#@fasken.com",
  },
  {
    key: 3,
    title: "M. Craig Brown",
    number: "+1 416 868 3143",
    email: "cbrown#@fasken.com",
  },
  {
    key: 4,
    title: "M. Craig Brown",
    number: "+1 416 868 3143",
    email: "cbrown#@fasken.com",
  },
  {
    key: 5,
    title: "M. Craig Brown",
    number: "+1 416 868 3143",
    email: "cbrown#@fasken.com",
  },
  {
    key: 6,
    title: "M. Craig Brown",
    number: "+1 416 868 3143",
    email: "cbrown#@fasken.com",
  },
  {
    key: 7,
    title: "M. Craig Brown",
    number: "+1 416 868 3143",
    email: "cbrown#@fasken.com",
  },
  {
    key: 8,
    title: "M. Craig Brown",
    number: "+1 416 868 3143",
    email: "cbrown#@fasken.com",
  },
];

export const JOB_DETAILS_DATA = [
  {
    id: 1,
    phase: "Phase 1",
    status: "Phase Completed",
    price: "$18",
    date: "October, 2024",
    description:
      "Figma ipsum component variant main layer. Background arrow hand fill overflow scale team pen layout group. Vector reesizing rotate rotate group. Plugin frame create scale project library. Italic plugin rectangle group clip strikethrough prototype mask auto subtract. Fill opacity team share rotate layer blur scale polygon union. Layer reesizing scale layout union distribute. ",
  },

  {
    id: 2,
    phase: "Phase 2",
    status: "Approval Pending",
    price: "$18",
    date: "October, 2024",
    description:
      "Figma ipsum component variant main layer. Background arrow hand fill overflow scale team pen layout group. Vector reesizing rotate rotate group. Plugin frame create scale project library. Italic plugin rectangle group clip strikethrough prototype mask auto subtract. Fill opacity team share rotate layer blur scale polygon union. Layer reesizing scale layout union distribute. ",
  },
  {
    id: 3,
    phase: "Phase 3",
    status: "Mark as completed",
    price: "$18",
    date: "October, 2024",
    description:
      "Figma ipsum component variant main layer. Background arrow hand fill overflow scale team pen layout group. Vector reesizing rotate rotate group. Plugin frame create scale project library. Italic plugin rectangle group clip strikethrough prototype mask auto subtract. Fill opacity team share rotate layer blur scale polygon union. Layer reesizing scale layout union distribute. ",
  },

  {
    id: 3,
    phase: "Phase 4",
    status: "Phase Edit Request Declined by the Client",
    price: "$18",
    date: "October, 2024",
    description:
      "Figma ipsum component variant main layer. Background arrow hand fill overflow scale team pen layout group. Vector reesizing rotate rotate group. Plugin frame create scale project library. Italic plugin rectangle group clip strikethrough prototype mask auto subtract. Fill opacity team share rotate layer blur scale polygon union. Layer reesizing scale layout union distribute. ",}
  ]
      export const REWARDS = [
  {
    id: 1,
    image: "/images/reward.png",
    username: "Karin Bezuidenhout",
    title: "Reward Title",
    date: "Jun 25, 2024",
  },
  {
    id: 2,
    image: "/images/reward.png",
    username: "Karin Bezuidenhout",
    title: "Reward Title",
    date: "Jun 25, 2024",
  },
  {
    id: 3,
    image: "/images/reward.png",
    username: "Karin Bezuidenhout",
    title: "Reward Title",
    date: "Jun 25, 2024",
  },
  {
    id: 4,
    image: "/images/reward.png",
    username: "Karin Bezuidenhout",
    title: "Reward Title",
    date: "Jun 25, 2024",
  },
  {
    id: 5,
    image: "/images/reward.png",
    username: "Karin Bezuidenhout",
    title: "Reward Title",
    date: "Jun 25, 2024",
  },
  {
    id: 6,
    image: "/images/reward.png",
    username: "Karin Bezuidenhout",
    title: "Reward Title",
    date: "Jun 25, 2024",
  },
  {
    id: 7,
    image: "/images/reward.png",
    username: "Karin Bezuidenhout",
    title: "Reward Title",
    date: "Jun 25, 2024",
  },
  {
    id: 8,
    image: "/images/reward.png",
    username: "Karin Bezuidenhout",
    title: "Reward Title",
    date: "Jun 25, 2024",
  },
];
