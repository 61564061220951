import React, { useState } from "react";
import classes from "./EditSubscriptionModal.module.css";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Put } from "../../Axios/AxiosFunctions";
import { Input } from "../../Component/Input/Input";
import { baseURL } from "../../config/apiUrl";
import { apiHeader } from "../../utils/helper";
import CheckCrossButton from "../../Component/CheckCrossButton/CheckCrossButton";
import RenderToast from "../../Component/RenderToast";

export default function EditSubscriptionModal({ show, setShow, id, getData }) {
  const [subscriptionAmount, setSubscriptionAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const { accessToken } = useSelector((state) => state?.authReducer);

  const editSubscription = async () => {
    setLoading(true);
    const amount = parseFloat(subscriptionAmount);

    const res = await Put(
      baseURL("Subscription"),
      {
        amount,
        id,
      },
      apiHeader(accessToken)
    );

    if (res) {
      setSubscriptionAmount("");
      setShow(false);
      RenderToast({
        type: "success",
        message: `Successfully Edited Subscription.`,
      });
    }
    getData();
    setLoading(false);
  };

  return (
    <>
      <style jsx>{`
        .modal-content {
          width: 100%;
          border-radius: 20px;
          overflow: hidden;
        }
        // .modal-body {
        // width:90%

        //   padding: 24px;
        // }
        .modal-header {
          flex-direction: column;
          background: var(--black-color);
          border-bottom: none;
          padding: 0.75rem;
        }
        .name {
          font-size: 18px;
          color: var(--black-color);
        }
      `}</style>
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header>
          <h4 className={[classes.headingText].join(" ")}>Edit Subscription</h4>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div>
              <Input
                className={classes.input}
                placeholder={"Amount ($)"}
                label={"Enter Subscription Amount"}
                value={subscriptionAmount}
                setter={setSubscriptionAmount}
                type="decimal"
              />
            </div>
            <div className={classes.buttons}>
              <CheckCrossButton
                onCheck={editSubscription}
                onCross={() => setSubscriptionAmount("")}
                loading={loading}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
