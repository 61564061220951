import React from "react";
import { Loader } from "../Loader";
import NoData from "../NoData/NoData";

export default function Content({
  loading = false,
  data = [],
  noDataText = "",
  children,
  dataObject = false,
}) {
  if (loading) return <Loader />;

  if (!dataObject) {
    if (!data?.length) return <NoData text={noDataText} />;
  } else if (dataObject) {
    if (!data || !Object.keys(data || {}).length)
      return <NoData text={noDataText} />;
  }

  return children;
}
