import axios from "axios";
import RenderToast from "../Component/RenderToast";
import { signOutRequest } from "../store/auth/authSlice";

/**
 * @description Sends a Get request to api
 * @param {String} route
 * @example "/api/route"
 * @returns Promise<any>
 */

const getErrorMsg = (error = null) => {
  console.log(error, "error");
  const message = error?.response?.data?.message;
  let errorMessage = "";

  if (Array.isArray(message)) {
    errorMessage = message[0];
  } else if (Array.isArray(message?.error) && message?.error[0]) {
    errorMessage = message?.error[0];
  } else {
    errorMessage = message;
  }

  return errorMessage;
};

let Get = async (route, accessToken, showAlert = true, dispatch) => {
  const options = accessToken
    ? {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    : {
        headers: {
          Accept: "application/json",
        },
      };
  try {
    const response = await axios.get(route, options);
    return response;
  } catch (error) {
   
    const errorMessage = getErrorMsg(error);

    if ((error?.response?.status === 401) & dispatch) {
      dispatch(signOutRequest());
    }

    if (showAlert == true) {
      if (error?.message === "Network Error") {
        RenderToast({
          type: "error",
          message: `${error?.message} : Please Check Your Network Connection`,
          position: "top-center",
        });
      } else {
        RenderToast({
          type: "error",
          message: errorMessage,
          position: "top-center",
        });
      }
    }

    if(error?.response?.status === 404) return error?.response?.status 

  }
};

/**
 * @description Sends a post request to api
 * @param {String} route
 * @example "/api/route"
 * @param {Object} data
 * @example {foo:bar}
 * @returns Promise<any>
 */

let Post = async (
  route,
  data,
  headers,
  showAlert = true,
  returnError = false,
  dispatch
) => {
  try {
    return await axios.post(route, data, headers);
  } catch (error) {
    const errorMessage = getErrorMsg(error);

    if ((error?.response?.status === 401) & dispatch) {
      dispatch(signOutRequest());
    }

    if (showAlert == true) {
      if (error?.message === "Network Error") {
        RenderToast({
          type: "error",
          message: `${error?.message} : Please Check Your Network Connection`,
          position: "top-center",
        });
      } else {
        RenderToast({
          type: "error",
          message: errorMessage,
          position: "top-center",
        });
      }
    }
  }
};

/**
 * @description Sends a post request to api
 * @param {String} route
 * @example "/api/route"
 * @param {Object} data
 * @example {foo:bar}
 *   @returns Promise<any>
 */

let Patch = async (route, data, headers, showAlert = true, dispatch) => {
  try {
    return await axios.patch(route, data, headers);
  } catch (error) {
    const errorMessage = getErrorMsg(error);

    if ((error?.response?.status === 401) & dispatch) {
      dispatch(signOutRequest());
    }

    if (showAlert == true) {
      if (error?.message === "Network Error") {
        RenderToast({
          type: "error",
          message: `${error?.message} : Please Check Your Network Connection`,
          position: "top-center",
        });
      } else {
        RenderToast({
          type: "error",
          message: errorMessage,
          position: "top-center",
        });
      }
    }
  }
};

/**
 * @description Sends a post request to api
 * @param {String} route
 * @example "/api/route"
 * @param {Object} data
 * @example {foo:bar}
 *   @returns Promise<any>
 */

let Put = async (route, data, headers, showAlert = true, dispatch) => {
  try {
    return await axios.put(route, data, headers);
  } catch (error) {
    const errorMessage = getErrorMsg(error);

    if ((error?.response?.status === 401) & dispatch) {
      dispatch(signOutRequest());
    }

    if (showAlert == true) {
      if (error?.message === "Network Error") {
        RenderToast({
          type: "error",
          message: `${error?.message} : Please Check Your Network Connection`,
          position: "top-center",
        });
      } else {
        RenderToast({
          type: "error",
          message: errorMessage,
          position: "top-center",
        });
      }
    }
  }
};

/**
 * @description Sends a Delete request to api
 * @param {String} route
 * @example "/api/route"
 * @param {Object} data
 * @example {foo:bar}
 *   @returns Promise<any>
 */

let Delete = async (route, data, headers, showAlert = true, dispatch) => {
  try {
    const dataAndHeader = {
      ...(data && { data }),
      ...(headers && { headers }),
    };
    return data == null
      ? await axios.delete(route, headers)
      : await axios.delete(route, dataAndHeader);
  } catch (error) {
    const errorMessage = getErrorMsg(error);

    if ((error?.response?.status === 401) & dispatch) {
      dispatch(signOutRequest());
    }

    if (showAlert == true) {
      if (error?.message === "Network Error") {
        RenderToast({
          type: "error",
          message: `${error?.message} : Please Check Your Network Connection`,
          position: "top-center",
        });
      } else {
        RenderToast({
          type: "error",
          message: errorMessage,
          position: "top-center",
        });
      }
    }
  }
};

export { Delete, Get, Patch, Post, Put };
