import React, { useState } from "react";
import { FaCheck } from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";
import { RxCrossCircled } from "react-icons/rx";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Post } from "../../Axios/AxiosFunctions";
import { Input } from "../../Component/Input/Input";
import { baseURL } from "../../config/apiUrl";
import { apiHeader, mergeClass, validateEmail } from "../../utils/helper";
import { Loader } from "../Loader";
import RenderToast from "../RenderToast";
import classes from "./FormWithHeading.module.css";

export default function FormWithHeading() {
  const navigate = useNavigate();
  const { user, isLogin, accessToken } = useSelector(
    (state) => state?.authReducer
  );
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const clearFields = () => {
    setLastName("");
    setFirstName("");
    setEmail("");
    navigate(-1);
  };
  const handleSubmit = async () => {
    if (!firstName || !lastName || !email) {
      RenderToast({ type: "error", message: "Kindly fill All inputs" });
    } else if (!validateEmail(email)) {
      RenderToast({ type: "error", message: "Kindly fill correct email" });
    } else {
      setLoading(true);
      const response = await Post(
        baseURL(`User/subadmin`),
        { firstName, lastName, email },
        apiHeader(accessToken)
      );
      if (response) {
        RenderToast({ type: "success", message: "Successfully Created " });
        setLoading(false);

        clearFields();
      }
      setLoading(false);
    }
  };
  const inputStyles = {
    borderRadius: "0px",
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <div>
      <h2 className={classes.heading}>Add New Sub Admin</h2>
      <div className={classes.mainBox}>
        <Input
          type="text"
          value={firstName}
          setter={setFirstName}
          placeholder={"First Name"}
          maxLength="255"
        />
        <Input
          type="text"
          value={lastName}
          setter={setLastName}
          placeholder={"Last Name"}
          maxLength="255"
        />
        <Input
          type="email"
          value={email}
          setter={setEmail}
          placeholder={"Email"}
          rightIcon={<MdOutlineMailOutline />}
          maxLength="255"
        />

        <div className={classes.flexGap}>
          <span className={classes.circle} onClick={handleSubmit}>
            <FaCheck color="var(--success-color)" />
          </span>
          <span
            className={mergeClass(classes.circle, classes.redCol)}
            onClick={clearFields}
          >
            <RxCrossCircled color="var(--red-color)" size={24} />
          </span>
        </div>
      </div>
    </div>
  );
}
