import classes from "./CategoryCard.module.css";
import { BiSolidEditAlt } from "react-icons/bi";
import { MdDeleteOutline } from "react-icons/md";
import { MdOutlineDateRange } from "react-icons/md";
import { Button } from "../Button/Button";
import AreYouSureModal from "../../modals/AreYouSureModal";
import { useState } from "react";

const CategoryCard = ({ data, handleDelete, handleEdit, isServiceCategory = false }) => {
  const [modal, setModal] = useState(false);
  return (
    <div className={classes.service_cards_container}>
      <div className={classes.service_card}>
        <div className={classes.card}>
          <div className={classes.subDivTitle}>
            <h5 className={"maxLine1"} title={!isServiceCategory && data?.name}>
              {isServiceCategory ? data?.serviceCategoryName : data?.name}
            </h5>
            {/* <p className="d-flex align-items-center gap-2 mt-3">
              <MdOutlineDateRange size={20} />
              <b
                style={{
                  color: "#2F5456",
                  fontFamily: "var(--ff-primary-bold)",
                }}
              >
                {data?.date}
              </b>
            </p> */}
          </div>
          <div className={classes.user_icon}>
           {!isServiceCategory && <BiSolidEditAlt
              size={30}
              color="#36696B"
              onClick={handleEdit}
              className={classes.editIcon}
            />}
            {/* <MdDeleteOutline size={25} color="#36696B" onClick={handleDelete} /> */}
           {!isServiceCategory ?
            <Button
              className={classes.btn}
              variant={data?.isEnabled ? "secondary" : "primary"}
              label={data?.isEnabled ? "Disable" : "Enable"}
              onClick={handleDelete}
            /> : 
            <Button
              className={classes.btn}
              variant="primary"
              label="Edit Commission Value"
              onClick={handleEdit}
            />}
          </div>
        </div>

        <div className={classes.subDivPercent}>
          <p>Commission</p>
          <h5>{data?.commission} %</h5>
        </div>
      </div>
    </div>
  );
};

export default CategoryCard;
