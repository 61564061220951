export const API_URL =
  // "https://saferwealth-api-qa.thefintechprojects.com";
  "https://saferwealth-api-client.thefintechprojects.com";

export const S3_URL = "";

export const baseURL = (link) => `${API_URL}/api/${link}`;
export const mediaUrl = (url) => url;

export const imageUrl = (url) => {
  if (!url) return "";
  if (url.startsWith("/")) return url;
  const result = url.indexOf("http");
  const imageRenderUrl = result === -1 ? `${API_URL}/media/${url}` : url;

  return imageRenderUrl;
};
