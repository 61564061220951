import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { MdOutlineArrowOutward } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import RenderToast from "../../Component/RenderToast";
import { Logo } from "../../constant/imagePath";
import { isMobileViewHook } from "../../customHooks/isMobileViewHook";
import { HEADER, HEADER_SUB_ADMIN, POPOVER } from "../../data/app-data";
import AreYouSureModal from "../../modals/AreYouSureModal";
import { signOutRequest } from "../../store/auth/authSlice";
import DesktopHeader from "./DesktopHeader";
import { MobileHeader } from "./MobileHeader";

const Header = ({
  backgroundColor,
  containerClass,
  className,
  logo = Logo,
  customStyle,
  headerClassName,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authReducer);

  let headerLinks = [];
  let popover = POPOVER;

  if (user?.userRoleId == "1") {
    headerLinks = HEADER;
  } else if (user?.userRoleId == "4") {
    headerLinks = HEADER_SUB_ADMIN;
  }

  const [isMobile, setIsMobile] = useState(false);
  const [isSticky, setSticky] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const onLogout = () => {
    setShow(true);
  };

  const logout = async () => {
    dispatch(signOutRequest());
    setShow(false);
    RenderToast({ type: "success", message: "Successfully Logout" });
  };

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const maxHeight =
        document.documentElement.scrollHeight - window.innerHeight;
      const scrollPert = parseInt((window.scrollY * 100) / maxHeight);
      if (scrollPert > 20) setSticky(true);
      else setSticky(false);
    });
    isMobileViewHook(setIsMobile, 992);
    return () => {
      document.removeEventListener("scroll", () => {});
    };
  }, []);

  useEffect(() => {
    isMobileViewHook(setIsMobile, 992);
  }, []);

  return (
    <header
      style={{
        ...(isSticky && {
          position: "fixed",
          left: 0,
          right: 0,
          top: 0,
          backgroundColor: "var(--primary-color) !important",
          transition: "transform 0.2s ease-in",
        }),
        ...(isSticky && { animation: "0.2s forwards headerInAnimation" }),
        background: "var(--dashboard-bg)",
        boxShadow: "0px 4px 24px 0px #0000001F",
        ...(!isSticky && {
          position: "relative",
        }),
        zIndex: 99,
      }}
      className={headerClassName}
    >
      {isMobile ? (
        <MobileHeader
          logo={logo}
          customStyle={customStyle}
          headerLinks={headerLinks}
          HeaderButtons={HEADER_BUTTONS}
          isLoggedIn
          onLogout={onLogout}
          popover={popover}
          user={user}
        />
      ) : (
        <DesktopHeader
          logo={logo}
          backgroundColor={backgroundColor}
          containerClass={containerClass}
          className={className}
          headerLinks={headerLinks}
          HeaderButtons={HEADER_BUTTONS}
          isLoggedIn
          onLogout={onLogout}
          popover={popover}
          user={user}
        />
      )}

      {show && (
        <AreYouSureModal
          Icon={null}
          show
          setShow={setShow}
          isApiCall={loading}
          onClick={logout}
          title="Logout"
          subTitle="Are you sure, you want to logout?"
        />
      )}
    </header>
  );
};

export default Header;

Header.propTypes = {
  backgroundColor: PropTypes.string,
  containerClass: PropTypes.string,
  className: PropTypes.string,
  logo: PropTypes.object,
  customStyle: PropTypes.object,
};

const HEADER_BUTTONS = [
  {
    label: "Login",
    value: "/auth/login",
  },
  {
    label: "Join Now",
    value: "/auth/signup",
    icon: <MdOutlineArrowOutward size={22} />,
  },
];
