import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Get } from "../../Axios/AxiosFunctions";
import { baseURL } from "../../config/apiUrl";
import { RECORDS_LIMIT } from "../../data/constants";
import Content from "../Content/Content";
import LoadMore from "../LoadMore/LoadMore";
import ServiceCard from "../ServiceCard";
import classes from "./Services.module.css";
import useDebounce from "../../customHooks/useDebounce";
import SubHeader from "../SubHeader/SubHeader";
import { useNonInitialEffect } from "../../hooks/useNonInitialEffect";

export default function Services({ slug = "" }) {
  const { accessToken } = useSelector((state) => state?.authReducer);
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState(null);
  const [search, setSearch] = useState("");
  const searchDebounce = useDebounce(search, 500);

  const getData = async ({
    loadMore = false,
    PageNumber = 1,
    filters = {},
    search = "",
  }) => {
    const query = {
      userId: slug,
      PageSize: RECORDS_LIMIT,
      PageNumber,
      search: search,
      ...filters,
    };

    let queryParams = new URLSearchParams(query);
    queryParams = queryParams?.toString();

    setLoading(loadMore ? "loadMore" : "loading");

    const res = await Get(
      baseURL(`ProfessionalServices?${queryParams}`),
      accessToken,
      false
    );

    if (res) {
      const data = res?.data;
      setTotalPages(data?.totalPages);
      setData((prevData) => {
        const _data = data?.data || [];
        if (loadMore) return [...prevData, ..._data];
        return _data;
      });
    }

    setLoading("");
  };

  useEffect(() => {
    getData({});
  }, []);

  useNonInitialEffect(() => {
    getData({
      filters,
      search: searchDebounce,
    });
  }, [filters, searchDebounce]);

  return (
    <Col>
      <div className={classes.subHeadClass}>
        <SubHeader
          title={""}
          showSearch
          showMultiFilter
          filters={filters}
          setSearch={setSearch}
          showServiceCategoryFilter={true}
          showAmountFilter={true}
          search={search}
          {...{
            setFilters,
          }}
        />
      </div>
      <Content
        {...{
          loading: loading === "loading",
          data,
          noDataText: "No Service Found",
        }}
      >
        <div className={classes.servicesTabDiv}>
          <Row>
            {data?.map((item, i) => (
              <Col md={6} key={i} className="mb-3">
                <ServiceCard
                  services={item}
                  onClick={() => navigate(`/services/${item?.id}`)}
                />
              </Col>
            ))}
          </Row>
          <LoadMore
            {...{
              page,
              totalPages,
              loading: loading === "loadMore",
              setPage: () => {
                const _page = page + 1;
                setPage(_page);
                getData({ loadMore: true, PageNumber: _page });
              },
            }}
          />
        </div>
      </Content>
    </Col>
  );
}
