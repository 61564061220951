import PropTypes from "prop-types";
import { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import classes from "./input.module.css";
import { InputGroup } from "react-bootstrap";

/**
 * Primary UI component for user interaction
 */
export const Input = ({
  type,
  label,
  label2, // sub label
  value, // input value
  setter, // setValue
  noBorder,
  placeholder,
  disabled,
  parentCustomStyle, // Main Div Inline Style
  customStyle, // Input Container inline Style
  inputStyle, // Input inline Style
  labelStyle, // Label inline Style
  error, // Show Error Boolean
  errorText, // Error Text
  leftIcon, // Icon For Input
  rightIcon,
  regexType,
  labelOnTop = false,
  prefix = "",
  ...props
}) => {
  const [passToggle, setPassToggle] = useState(false);

  // Function to handle input change
  const handleChange = (e) => {
    let newValue = e.target.value;

    if (type === "decimal") {
      // Allow only numeric characters and decimal point
      newValue = newValue.replace(/[^0-9.]/g, ""); // Remove non-numeric characters except decimal point
      // Ensure only one decimal point
      if (newValue.split(".").length > 2) {
        newValue = newValue.slice(0, -1);
      }
    } else if (type === "number") {
      // Allow only numeric characters (no decimal point)
      newValue = newValue.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    }

    setter(newValue);
  };

  // Function to handle keydown event for number and decimal type inputs
  const handleKeyDown = (e) => {
    if (type === "decimal" || type === "number") {
      // Prevent non-numeric characters (excluding decimal point for decimal type) and special characters like "e"
      if (["e", "E", "+", "-"].includes(e.key)) {
        e.preventDefault();
      }
    }
  };

  // Determine input container style
  const inputContainerStyleObject = {
    ...(error && { border: `1px solid red` }),
    ...(leftIcon && { paddingLeft: "50px" }),
  };

  return (
    <>
      <div
        className={`${[
          classes.Container,
          labelOnTop ? classes.labelOnTop : "",
        ].join(" ")}`}
        style={{ ...parentCustomStyle }}
      >
        {label && (
          <label
            htmlFor={`input${label}`}
            className={`${[
              classes.labelText,
              disabled && classes.disabled,
              labelOnTop ? classes.onTopLabel : "",
            ].join(" ")}`}
            style={{ ...labelStyle }}
          >
            {label} {label2 && label2}
          </label>
        )}
        <div
          className={`${[classes.inputPassContainer].join(" ")}`}
          style={{ ...customStyle }}
        >
          {prefix && (
            <InputGroup.Text id="basic-addon1" className={classes?.prefix}>
              {prefix}
            </InputGroup.Text>
          )}
          {leftIcon && <div className={classes.leftIconBox}>{leftIcon}</div>}
          <input
            value={value}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            disabled={disabled}
            placeholder={placeholder}
            type={passToggle ? "text" : type}
            id={`input${label}`}
            className={` ${[
              classes.inputBox,
              noBorder && classes.noBorder,
            ].join(" ")}`}
            style={{ ...inputContainerStyleObject, ...inputStyle }}
            onBlur={() => {
              setter(value?.trim());
            }}
            {...props}
          />
          {rightIcon && <div className={classes.rightIcon}>{rightIcon}</div>}

          {type === "password" && !passToggle && (
            <AiOutlineEyeInvisible
              className={classes.passwordIcon}
              onClick={() => setPassToggle(!passToggle)}
              fontSize={25}
            />
          )}
          {type === "password" && passToggle && (
            <AiOutlineEye
              className={classes.passwordIcon}
              onClick={() => setPassToggle(!passToggle)}
              fontSize={25}
            />
          )}
        </div>
        {error && (
          <p className={`mt-2 ${[classes.errorText].join(" ")}`}>{errorText}</p>
        )}
      </div>
    </>
  );
};

Input.propTypes = {
  type: PropTypes.oneOf(["text", "password", "number", "decimal"]).isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  setter: PropTypes.func.isRequired, // Updated to func
  noBorder: PropTypes.bool,
  disabled: PropTypes.bool,
  customStyle: PropTypes.object, // Updated to object
  error: PropTypes.bool,
  errorText: PropTypes.string,
  label2: PropTypes.string,
  regexType: PropTypes.string,
  labelOnTop: PropTypes.bool,
  prefix: PropTypes.string,
};

Input.defaultProps = {
  type: "text",
  placeholder: "enter text",
  value: "",
  noBorder: false,
  disabled: false,
  error: false,
  errorText: "An error has occurred, check your details!",
  regexType: "text",
  labelOnTop: false,
  prefix: "",
};
