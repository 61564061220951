import React from "react";
import { Button } from "../Button/Button";
import classes from "./LoadMore.module.css";

export default function LoadMore({
  loading = false,
  setPage = () => {},
  page = 1,
  totalPages = 1,
}) {
  console.log("🚀 ~ loading:", loading)
  console.log("🚀 ~ totalPages:", totalPages)
  console.log("🚀 ~ page:", page)
  if (totalPages <= page && !loading) return null;

  return (
    <div className="loadMoreBtn">
      <Button
        variant="secondary"
        disabled={loading}
        onClick={() => setPage((prev) => prev + 1)}
        className={classes.btn}
      >
        {loading ? "Loading..." : "Load More"}
      </Button>
    </div>
  );
}
