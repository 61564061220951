import React from "react";
import { Col, Row } from "react-bootstrap";
import Statuses from "../Status";
import Styles from "./ProfessionalVarification.module.css";
import Content from "../Content/Content";

const ProfessionalVarification = ({ tableHeadings, professionals }) => {
  return (
    <>
      <div className={Styles.tableSec}>
        <Row className="mb-3">
          <Col>
            <strong>{tableHeadings.heading}</strong>
          </Col>
        </Row>

        <Content
          data={professionals}
          noDataText="No Professional Verification Found"
        >
          <div className={Styles.proDetailTable}>
            <Row>
              <Col lg={4}>
                <strong>Email</strong>
              </Col>
              <Col lg={4}>
                <strong>Status</strong>
              </Col>
              <Col lg={4}>
                <strong>Comments</strong>
              </Col>
            </Row>
            <div className={Styles.proTableBody}>
              {professionals.map((pro, index) => (
                <div className={Styles.proTableHead2} key={index}>
                  <Row>
                    <Col lg={4}>{pro.email}</Col>
                    <Col lg={4}>
                      <div className={Styles.status}>
                        <Statuses status={pro.status?.toLowerCase()} />
                      </div>
                    </Col>
                    <Col lg={4}>{pro?.comments || `N/A`}</Col>
                  </Row>
                </div>
              ))}
            </div>
          </div>
        </Content>
      </div>
    </>
  );
};

export default ProfessionalVarification;
