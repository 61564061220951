"use client";

import { IoArrowBack, IoArrowForward } from "react-icons/io5";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import { Button } from "../Button/Button";
import classes from "./pagination.module.css";

const MAX_PAGES_TO_SHOW = 3;

export default function Pagination({
  totalPages = 1,
  currentPage = 1,
  setCurrentPage = () => {},
}) {
  const pageNumbers = Array(totalPages)
    .fill(totalPages)
    .map((_, index) => index + 1);

  const startIndex =
    currentPage >= MAX_PAGES_TO_SHOW
      ? currentPage -
        MAX_PAGES_TO_SHOW +
        (currentPage === pageNumbers[pageNumbers.length - 1] ? 0 : 1)
      : 0;

  const noOfPagesToShow = [...pageNumbers].splice(
    startIndex,
    MAX_PAGES_TO_SHOW
  );

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const renderPageNumbers = () => {
    return (
      <ul style={{ listStyleType: "none", padding: 0 }}>
        {totalPages > MAX_PAGES_TO_SHOW && (
          <Button
            variant="outlined"
            onClick={() => setCurrentPage(1)}
            disabled={currentPage === 1}
            className={classes.iconBtn}
            rightIcon={<MdKeyboardDoubleArrowLeft fontSize={20} />}
          />
        )}

        {noOfPagesToShow.map((page, index) => (
          <li
            key={index}
            className={currentPage === page ? classes.active : ""}
            onClick={() => setCurrentPage(page)}
          >
            {page}
          </li>
        ))}

        {totalPages > MAX_PAGES_TO_SHOW && (
          <Button
            variant="outlined"
            onClick={() => setCurrentPage(totalPages)}
            disabled={currentPage === totalPages}
            className={classes.iconBtn}
            rightIcon={<MdKeyboardDoubleArrowRight fontSize={20} />}
          />
        )}
      </ul>
    );
  };

  if (totalPages < 2) return null;

  return (
    <div className={classes.pagination}>
      <Button
        variant="secondary"
        label="Previous"
        onClick={goToPrevPage}
        disabled={currentPage === 1}
        className={classes.btn}
        leftIcon={<IoArrowBack fontSize={20} />}
      />

      {renderPageNumbers()}

      <Button
        variant="secondary"
        label="Next"
        onClick={goToNextPage}
        disabled={currentPage === totalPages}
        className={classes.btn}
        rightIcon={<IoArrowForward fontSize={20} />}
      />
    </div>
  );
}
