import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Get, Put } from "../../Axios/AxiosFunctions";
import Content from "../../Component/Content/Content";
import Header from "../../Component/Header/Header";
import LoadMore from "../../Component/LoadMore/LoadMore";
import ProfileNameEmail from "../../Component/ProfileNameEmail/ProfileNameEmail";
import SubAdminCard from "../../Component/SubAdminCard/SubAdminCard";
import SubHeader from "../../Component/SubHeader/SubHeader";
import { baseURL } from "../../config/apiUrl";
import useDebounce from "../../customHooks/useDebounce";
import { RECORDS_LIMIT } from "../../data/constants";
import { useNonInitialEffect } from "../../hooks/useNonInitialEffect";
import AreYouSureModal from "../../modals/AreYouSureModal";
import { apiHeader } from "../../utils/helper";
import classes from "./SubAdmin.module.css";

export default function SubAdmin() {
  const navigate = useNavigate();
  const { accessToken } = useSelector((state) => state?.authReducer);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [areYouSureModal, setAreYouSureModal] = useState(false);
  const [content, setContent] = useState("");
  const [userId, setUserId] = useState("");
  const [status, setStatus] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState(null);
  const [search, setSearch] = useState("");
  const searchDebounce = useDebounce(search, 500);

  const changeStatus = async () => {
    setLoading("changeStatus");

    const _status = !status;

    const response = await Put(
      baseURL(`User/status`),
      { userId: userId, status: _status },
      apiHeader(accessToken)
    );

    if (response) {
      setData((prev) =>
        prev?.map((item) => {
          if (item?.id === userId) {
            return {
              ...item,
              isBlocked: _status,
            };
          }
          return item;
        })
      );
    }

    setAreYouSureModal(false);
    setLoading("");
  };

  const getData = async ({
    PageNumber = 1,
    loadMore = false,
    filters = {},
    search = "",
  }) => {
    const query = {
      userRoleId: 4,
      PageSize: RECORDS_LIMIT,
      PageNumber,
      search,
      ...filters,
    };

    let queryParams = new URLSearchParams(query);
    queryParams = queryParams?.toString();

    setLoading(loadMore ? "loadMore" : "loading");

    const response = await Get(
      baseURL(`Users?${queryParams}`),
      accessToken,
      false
    );

    if (response) {
      const data = response?.data;
      setTotalPages(data?.totalPages);
      setData((prev) => {
        const _data = data?.data || [];
        if (loadMore) return [...prev, ..._data];
        return _data;
      });
    }
    setLoading("");
  };

  useEffect(() => {
    getData({});
  }, []);

  useNonInitialEffect(() => {
    getData({
      filters,
      search: searchDebounce,
    });
  }, [filters, searchDebounce]);

  return (
    <div>
      <Header />
      <ProfileNameEmail onClick={() => navigate("/create-sub-admin")} showBtn />
      <Container className={classes.container}>
        <SubHeader
          title={"Sub Admins"}
          showSearch
          showMultiFilter
          filters={filters}
          setSearch={setSearch}
          showStatusFilter={true}
          search={search}
          {...{
            setFilters,
          }}
        />
        <Content
          {...{
            loading: loading === "loading",
            data,
            noDataText: "No Sub Admins Found",
          }}
        >
          <Row className="gy-4">
            {data?.map((item, index) => {
              return (
                <Col lg={3} md={4} id={index} className="mb-4">
                  <SubAdminCard
                    data={item}
                    modal={areYouSureModal}
                    modalClose={setAreYouSureModal}
                    setContent={setContent}
                    setUserId={setUserId}
                    setStatus={setStatus}
                  />
                </Col>
              );
            })}
          </Row>
          <LoadMore
            {...{
              page,
              totalPages,
              loading: loading === "loadMore",
              setPage: () => {
                const _page = page + 1;
                setPage(_page);
                getData({ loadMore: true, PageNumber: _page });
              },
            }}
          />
        </Content>
      </Container>
      {areYouSureModal && (
        <AreYouSureModal
          show={areYouSureModal}
          setShow={setAreYouSureModal}
          subTitle={`Are You Sure you want to ${content}?`}
          onClick={changeStatus}
          loading={loading === "changeStatus"}
        />
      )}
    </div>
  );
}
