import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FiEdit } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { Get, Put } from "../../Axios/AxiosFunctions";
import BackButton from "../../Component/BackButton/BackButton";
import { Button } from "../../Component/Button/Button";
import Header from "../../Component/Header/Header";
import { Input } from "../../Component/Input/Input";
import { ProfileWithEditButton } from "../../Component/ProfileWithEditButton";
import RenderToast from "../../Component/RenderToast";
import { baseURL } from "../../config/apiUrl";
import UpdatePasswordModal from "../../modals/UpdatePasswordModal";
import { updateUser } from "../../store/auth/authSlice";
import { apiHeader } from "../../utils/helper";
import classes from "./ProfileSetting.module.css";

const ProfileSetting = () => {
  const dispatch = useDispatch();
  const { accessToken, user } = useSelector((state) => state?.authReducer);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [photo, setPhoto] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);
  const [showModal, setshowModal] = useState(false);

  const inputStyles = {
    boxShadow: "none",
    border: "none",
    borderBottom: "1px solid var(--divider-color)",
  };

  // getUser
  const getUser = async () => {
    const url = baseURL("User");
    const response = await Get(url, accessToken);

    if (response) {
      const user = response?.data?.data;
      dispatch(updateUser(user));
    }
  };

  const handleUpdateUser = async () => {
    const url = baseURL("User");
    let params = {
      firstName,
      lastName,
    };

    for (let key in params) {
      if (!params[key]) {
        return RenderToast({
          type: "error",
          message: "Please fill the required fields",
        });
      }
    }

    params = {
      file: photo,
      firstName: firstName,
      lastName: lastName,
      phone: phoneNumber,
    };

    setLoading(true);

    const response = await Put(url, params, apiHeader(accessToken, true));

    if (response !== undefined) {
      await getUser();
      RenderToast({
        type: "success",
        message: `Successfully Updated`,
      });
      setEnableEdit(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    setFirstName(user ? user?.firstName : "");
    setLastName(user ? user?.lastName : "");
    setPhoto(user?.profileImagePath ? user?.profileImagePath : "");
    setPhoneNumber(user?.phone ? user?.phone : "");
  }, [user]);

  return (
    <>
      <Header />
      <Container>
        <div className={classes.mainContainer}>
          <div className="my-4">
            <BackButton title="Back" />
          </div>
          <div className={classes.headerContainer}>
            <h5>Settings</h5>
          </div>
          <Row>
            <Col md={12} className={classes.imgCol}>
              <ProfileWithEditButton
                updateImage={photo}
                setUpdateImage={setPhoto}
                isEdit={enableEdit}
                onClick={() => {}}
              />
              {!enableEdit && (
                <div
                  className={classes.nameContainer}
                  onClick={() => {
                    setEnableEdit(!enableEdit);
                  }}
                >
                  <FiEdit
                    title="Enable Editing"
                    size={20}
                    className={classes.editIcon}
                  />
                </div>
              )}
            </Col>
            <Col md={12} className={classes.inputCol}>
              <Input
                placeholder={"First Name"}
                value={firstName}
                setter={setFirstName}
                label={"First Name"}
                customStyle={inputStyles}
                disabled={!enableEdit}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <Input
                placeholder={"Last Name"}
                value={lastName}
                setter={setLastName}
                label={"Last Name"}
                customStyle={inputStyles}
                disabled={!enableEdit}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <Input
                placeholder={"Email"}
                value={user?.email}
                setter={() => {}}
                label={"Email"}
                disabled={true}
                customStyle={inputStyles}
              />
            </Col>
          </Row>
          <div className="d-flex justify-content-between gap-3">
            <Button
              className={`${classes?.changePasswordBtn}`}
              variant="secondary"
              onClick={() => {
                setshowModal(true);
              }}
            >
              Change Password
            </Button>
            <Button
              label={loading ? "Updating..." : "Update"}
              disabled={loading || !enableEdit}
              onClick={handleUpdateUser}
            />
          </div>
        </div>
        {showModal && (
          <UpdatePasswordModal show={showModal} setShow={setshowModal} />
        )}
      </Container>
    </>
  );
};

export default ProfileSetting;
