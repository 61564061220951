import React from "react";
import { mediaUrl } from "../../config/apiUrl";
import classes from "./AppAvatar.module.css";
import { DemoProfile } from "../../constant/imagePath";

export default function AppAvatar({ imageURI = "", className = "" }) {
  return (
    <div className={`${classes.avatar} ${className && className}`}>
      <img
        className={classes.avatarImg}
        src={`${mediaUrl(imageURI)}`}
        alt="..."
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = DemoProfile;
        }}
      />
    </div>
  );
}
