import React from "react";
import { Col, Row } from "react-bootstrap";
import Content from "../Content/Content";
import Statuses from "../Status";
import Styles from "./ProfessionalClientRequest.module.css";

const ProfessionalClientRequest = ({ tableHeadings, client }) => {
  return (
    <>
      <div className={Styles.tableSec}>
        <div className={Styles.proDetailTable}>
          <Row>
            <Col>
              <strong>Client Verification</strong>
            </Col>
          </Row>
          <Content data={client} noDataText="No Client Verification Found">
            {client?.map((client, index) => (
              <div key={index} className={Styles.proDetailTable}>
                <Row>
                  <Col>
                    <div className={Styles.proTableHead}>
                      <p>
                        <strong>Client : </strong>
                        {`${client?.email} ${
                          client?.clientContact
                            ? `, ${client?.clientContact}`
                            : ""
                        }  `}
                      </p>
                    </div>
                  </Col>
                </Row>

                <div className={Styles.clientDiv}>
                  <div className={Styles.proTableHead2}>
                    <Row>
                      <Col lg={4}>
                        <strong>Email</strong>
                      </Col>
                      <Col lg={4}>
                        <strong>Status</strong>
                      </Col>
                      <Col lg={4}>
                        <strong>Comments</strong>
                      </Col>
                    </Row>
                  </div>

                  {client?.approvers?.map((e, i) => (
                    <div className={Styles.proTableHead2}>
                      <Row>
                        <Col lg={4}>{e?.email}</Col>
                        <Col lg={4}>
                          <Statuses status={e?.status?.toLowerCase()} />
                        </Col>
                        <Col lg={4}>{e?.comments || `N/A`}</Col>
                      </Row>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </Content>
        </div>
      </div>
    </>
  );
};

export default ProfessionalClientRequest;
