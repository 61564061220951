import React from "react";
import Header from "../../Component/Header/Header";
import BackButton from "../../Component/BackButton/BackButton";
import { Container } from "react-bootstrap";
import ChattingCard from "../../Component/ChatComponent/ChattingCard";

export default function SingleChatPage() {
  const queryString = window.location.search;
  const queryParams = new URLSearchParams(queryString);
  const senderId = queryParams?.get("SenderId");
  const receiverId = queryParams?.get("ReceiverId");
  const professionalServiceRequestId = queryParams?.get(
    "ProfessionalServiceRequestId"
  );
  const professionalName = queryParams?.get("professionalName");
  const supportId = queryParams?.get("supportId");
  const requesterId = queryParams?.get("requesterId");
  const status = queryParams?.get("status");

  let queryParamsObj;

  if(supportId){
    queryParamsObj = {supportId , senderId, status}
  }
  else{
    queryParamsObj = {
      senderId,
      receiverId,
      professionalServiceRequestId,
      professionalName,
    };
  }

  return (
    <div>
      <Header />
      {/* <BackButton /> */}
      <Container>
        <ChattingCard
          showInput={supportId ? true : false}
          singleChatView={true}
          queryParamsObj={queryParamsObj}
          supportChat={true}
          requesterId={requesterId}
        />
      </Container>
    </div>
  );
}
