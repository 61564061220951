import React, { useEffect, useState } from "react";
import Content from "../Content/Content";
import { Get } from "../../Axios/AxiosFunctions";
import { baseURL } from "../../config/apiUrl";
import { useSelector } from "react-redux";
import classes from "./DetailsOfProfessional.module.css";
import ProfessionalClientRequest from "../ProfessionalClientRequest/ProfessionalClientRequest";
import ProfessionalVarification from "../ProfessionalVarification/ProfessionalVarification";
import { PRO_HEADINGS } from "../../data/app-data";
import PortfolioCard from "../PortfolioCard/PortfolioCard";

export default function DetailsOfProfessional({ slug = "" }) {
  const { accessToken } = useSelector((state) => state?.authReducer);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);

    const res = await Get(
      baseURL(`User/details?userId=${slug}`),
      accessToken,
      false
    );

    if (res) {
      setData(res?.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Content
      noDataText="Professional Details Not Found"
      loading={loading}
      data={data}
      dataObject={true}
    >
      <div className={classes.mainCart}>
        <PortfolioCard isEditPen={false} data={data?.data?.profile} />

        <ProfessionalVarification
          professionals={data?.data?.professionalsVerification}
          tableHeadings={PRO_HEADINGS}
        />
        <ProfessionalClientRequest client={data?.data?.clientsVerification} />
      </div>
    </Content>
  );
}
