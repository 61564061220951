import React, { useRef, useState } from "react";
import { BiSolidCategory } from "react-icons/bi";
import { BsBoxSeamFill } from "react-icons/bs";
import { FaListAlt } from "react-icons/fa";
import { IoNotificationsSharp, IoSettingsSharp } from "react-icons/io5";
import { MdDashboard } from "react-icons/md";
import {
  RiArrowDownSFill,
  RiArrowUpSFill,
  RiLogoutBoxLine,
} from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import { API_URL } from "../../config/apiUrl";
import { BlackLogo } from "../../constant/imagePath";
import { signOutRequest } from "../../store/auth/authSlice";
import { clearNotiData } from "../../store/common/commonSlice";
import classes from "./SideBar.module.css";

const SideBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { chat_counter, noti_counter } = useSelector(
    (state) => state?.commonReducer
  );
  const { user, fcmToken } = useSelector((state) => state?.authReducer);

  const RenderItem = ({ icon, title, path, subMenu = [] }) => {
    const navigate = useNavigate();
    const active = useLocation()?.pathname;
    const [subnav, setSubnav] = useState(false);
    const subActive = subMenu.find((item, index) => item?.path == active);
    const showSubnav = () => setSubnav(!subnav);
    return (
      <>
        <div
          className={[
            classes?.listItemContainer,
            path == active && classes?.active,
            subActive && classes?.subActive,
            subnav && classes?.marginZero,
          ].join(" ")}
          onClick={() => {
            if (subMenu?.length > 0) {
              showSubnav(!subnav);
            } else {
              navigate(path);
            }
          }}
        >
          {icon}
          <span>{title}</span>
          {subMenu?.length > 0 &&
            (subnav ? (
              <RiArrowUpSFill
                size={20}
                color={"white"}
                className={classes?.dropDownIcon}
              />
            ) : (
              <RiArrowDownSFill
                size={20}
                color={"white"}
                className={classes?.dropDownIcon}
              />
            ))}
        </div>
        {subnav &&
          subMenu.map((item, index) => {
            return (
              <div
                className={[
                  classes?.innerItemContainer,
                  item?.path == active && classes?.active,
                ].join(" ")}
                key={index}
                onClick={() => {
                  navigate(item?.path);
                }}
              >
                {item?.icon}
                <span>{item.label}</span>
              </div>
            );
          })}
      </>
    );
  };

  const socket = useRef(null);

  const logout = () => {
    socket.current = io(API_URL);
    socket.current.emit("logout", { _id: user?._id, fcmToken });
    dispatch(signOutRequest());
    navigate("/");
  };

  return (
    <div className={classes?.mainContainer}>
      <div className={classes?.logoContainer}>
        <img src={BlackLogo} onClick={() => navigate("/")} alt="..." />
      </div>
      <div className={classes?.itemsContainer}>
        <RenderItem
          icon={<MdDashboard size={22} />}
          title={"Dashboard"}
          path={"/dashboard"}
        />
        <RenderItem
          icon={<BsBoxSeamFill size={20} />}
          title={"Vendors"}
          path={"/vendors"}
        />

        <RenderItem
          icon={<FaListAlt size={20} />}
          title={"CRUD's"}
          subMenu={[
            {
              icon: <BiSolidCategory size={20} />,
              label: "Categories",
              path: "/categories",
            },
          ]}
        />
        <div onClick={() => dispatch(clearNotiData())}>
          <RenderItem
            icon={<IoNotificationsSharp size={20} />}
            title={`Notifications ${
              noti_counter > 0 ? `(${noti_counter})` : ""
            }`}
            path={"/notifications"}
          />
        </div>
        <RenderItem
          icon={<IoSettingsSharp size={20} />}
          title={`Settings`}
          path={"/settings"}
        />
      </div>
      <div
        className={[classes?.listItemContainer, classes?.logoutBtn].join(" ")}
        onClick={logout}
      >
        <RiLogoutBoxLine size={22} />
        <span>Logout</span>
      </div>
    </div>
  );
};

export default SideBar;
