import React, { useEffect, useState } from "react";
import classes from "./SubscriptionView.module.css";
import { useSelector } from "react-redux";
import Header from "../../Component/Header/Header";
import ProfileNameEmail from "../../Component/ProfileNameEmail/ProfileNameEmail";
import BackButton from "../../Component/BackButton/BackButton";
import { Col, Container, Row } from "react-bootstrap";
import Content from "../../Component/Content/Content";
import { Get } from "../../Axios/AxiosFunctions";
import { baseURL } from "../../config/apiUrl";
import SubscriptionCard from "../../Component/SubscriptionCard/SubscriptionCard";

export default function SubscriptionView() {
  const { accessToken } = useSelector((state) => state?.authReducer);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);

    const response = await Get(baseURL(`Subscriptions`), accessToken, false);

    if (response) {
      setData(response?.data?.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    getData({});
  }, []);

  return (
    <div>
      <Header />
      <ProfileNameEmail />
      <Container>
        <Content
          {...{
            loading,
            data,
            noDataText: "No Subscriptions Found",
          }}
        >
          <Row className="gy-4 mt-4">
            {data?.map((item, index) => {
              return <Col md={6} sm={12} key={index}>
                <SubscriptionCard plan={item} getData={getData}/>
              </Col>;
            })}
          </Row>
        </Content>
      </Container>
    </div>
  );
}
