import { FaRegCircleUser } from "react-icons/fa6";
import classes from "../ChatComponent.module.css";
import { mergeClass } from "../../../utils/helper";
import { clockIcon } from "../../../constant/imagePath";
import moment from "moment/moment";

export default function ChatRoom({ data, isActive, onClick }) {
  const userTimeZone = moment.tz.guess();

  return (
    <div
      className={`${classes.chatRoomDiv} ${
        isActive ? classes.chatRoomDivActive : classes.chatRoomDivInactive
      }`}
      onClick={onClick}
    >
      <div className={classes.userIcon}>
        <FaRegCircleUser size={"30"} color="var(--user-icon-color)" />
      </div>
      <div className={classes.chatDetails}>
        <span
          className={mergeClass(
            classes.chatRoomUserName,
            classes.userName,
            "maxLine1"
          )}
        >
          {data?.receiverName}
        </span>
        <span
          className={mergeClass(
            classes.serviceName,
            "maxLine1"
          )}
        >
          {data?.professionalServiceName}
        </span>
        <div className={classes.chatDetailsSubDiv}>
          <img alt="clockImage" width={16} height={16} src={clockIcon} />
          <span className={mergeClass(classes.time,  classes.time1)}>{moment.utc(data?.lastMessageOn).tz(userTimeZone).format("lll")} </span>
          {/* <span className={mergeClass(classes.userName, classes.chatTime)}>
            {data?.time}{" "}
            <span className={mergeClass(classes.time)}>{moment.utc(data?.lastMessageOn).tz(userTimeZone).format("lll")}
              hrs ago
              </span>
          </span> */}
        </div>
      </div>
    </div>
  );
}
