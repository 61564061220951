import { useEffect, useRef, useState } from "react";
import { FaArrowLeft } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { Get, Post } from "../../../Axios/AxiosFunctions";
import { API_URL, baseURL } from "../../../config/apiUrl";
import { RECORDS_LIMIT50 } from "../../../data/constants";
import { apiHeader, mergeClass } from "../../../utils/helper";
import { Button } from "../../Button/Button";
import Content from "../../Content/Content";
import LoadMore from "../../LoadMore/LoadMore";
import classes from "../ChatComponent.module.css";
import ChatInput from "../ChatInput";
import ChattingMessages from "../ChattingMessages";
import ChatHeader from "../ChatHeader";
import * as signalR from "@microsoft/signalr";

export default function ChattingCard({
  onBackClick,
  showInput = true,
  queryParamsObj = {},
  client = false,
  singleChatView = false,
  chatNameSelectedRoom=null,
  senderId=null, 
  supportChat = false,
  requesterId= ""
}) {
  console.log("🚀 ~ queryParamsObj:", queryParamsObj)
  console.log("🚀 ~ supportChat:", supportChat)
  const { accessToken , user } = useSelector((state) => state?.authReducer);
  const msgEndRef = useRef(null);
  const connection = useRef(null);
  const userId = user?.id;

  const [userName, setUserName] = useState("");
  const [loading, setLoading] = useState(false);
  const [chatName, setChatName] = useState("");
  const [loadMore, setLoadMore] = useState(false);
  const [page, setPage] = useState(1);
  const [messages, setMessages] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  const handleBackClick = () => {
    window.history.back();
  };

  const getChatHistory = async ({
    _chatName = chatName,
    loadMore = false,
    PageNumber = 1,
  }) => {
    setLoading(true);
    loadMore && setLoadMore(true);

    try {
      const resp = await Get(
        baseURL(`${supportChat ? "SupportTicket" : "Chat"}/chat-history?chatName=${singleChatView ? _chatName : chatNameSelectedRoom}`),
        accessToken,
        false
      );

      if (resp) {
        const data = resp?.data;
        setMessages((prev) => [
          ...prev,
          ...(data?.data?.messages ? data?.data?.messages : []),
        ]);

        setTotalPages(data?.data?.totalPages);

        setUserName(resp?.data?.data?.userDetails?.receiverName);

        !loadMore &&
          setTimeout(() => {
            msgEndRef.current?.scrollIntoView({
              block: "nearest",
              behavior: "smooth",
            });
          }, 500);
      }
    } catch (error) {
      console.error("Failed to fetch chat history:", error);
    } finally {
      setLoading(false);
      setLoadMore(false);
    }
  };

  const createChatName = async () => {
    let queryParams = new URLSearchParams(queryParamsObj);
    const supportId = queryParams?.get("supportId");

    const resp = await Post(
      baseURL(`SupportTicket/chat`),
      {
        requesterId: requesterId,
        supportId: supportId
      },
      apiHeader(accessToken),
      false
    );

    if (resp) {
      setChatName(resp?.data?.data?.chatName);
      await getChatHistory({ chatName });
    }

    setLoading(false);
  };

  const getChatName = async () => {
    let queryParams = new URLSearchParams(queryParamsObj);
    queryParams = queryParams?.toString();

    setLoading(true);

    try {
      const resp = await Get(
        baseURL(`${supportChat ? "SupportTicket/chat" : "Chat/chat-name"}?${queryParams}`),
        accessToken,
        false
      );

      if (resp) {
        const _chatName = resp?.data?.data;
        if (_chatName) {
          setChatName(_chatName);
          await getChatHistory({ _chatName });
        }
        else if(!_chatName && supportChat){
          createChatName();
        }
      }
    } catch (error) {
      console.error("Failed to fetch chat name:", error);
    } finally {
      setLoading(false);
    }
  };

  const sendMessage = (message = "", callback = () => {}) => {
    if (message.trim() && connection?.current) {
      connection?.current
        ?.invoke("SendMessage", chatName, userId, message)
        .catch((err) => console.error("hub", err.toString()));

      callback();
    }
  };

  useEffect(() => {
    if(singleChatView) getChatName();
    else{
      setMessages([]); getChatHistory({});
    }
  }, [chatNameSelectedRoom]);

  useEffect(() => {
    if (chatName) {
      connection.current = new signalR.HubConnectionBuilder()
        .withUrl(`${API_URL}/chatHub`, {
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets,
        })
        .build();

      connection?.current
        .start()
        .then(() => console.log("SignalR connected"))
        .catch((err) => console.error(err.toString()));

      // Cleanup connection on component unmount
      return () => {
        connection?.current
          ?.stop()
          ?.catch((err) =>
            console.error("HubConnectionBuilder", err.toString())
          );
      };
    }
  }, [chatName]);

  useEffect(() => {
    if (chatName) {
      connection?.current?.on(chatName, (messageRequest) => {
        setMessages((prevMessages) => [...prevMessages, messageRequest]);
        setTimeout(() => {
          msgEndRef.current?.scrollIntoView({
            block: "nearest",
            behavior: "smooth",
          });
        }, 500);
      });
    }
  }, [chatName]);

  return (
    <div className={mergeClass(classes.chattingCard)}>
      <div className={classes.chattingCardSubDiv}>
        <Button
          className={classes.backButton}
          onClick={handleBackClick}
          variant="primary"
          leftIcon={<FaArrowLeft size={16} />}
        />
      </div>

      <ChatHeader userName={userName}/>

      <div className={mergeClass(classes.messagesDiv, "scrollbar")}>
        <Content
          {...{
            loading: loading,
            data: messages,
            noDataText: "No Chat History",
          }}
        >
          <LoadMore
            {...{
              page,
              totalPages,
              loading: loadMore,
              setPage: () => {
                const _page = page + 1;
                setPage(_page);
                getChatHistory({ loadMore: true, PageNumber: _page });
              },
            }}
          />

          {messages?.map((item, i) => (
            <ChattingMessages
              data={item}
              key={i}
              index={i}
              messages={messages}
              userId={singleChatView ? queryParamsObj?.senderId : senderId}
              professionalName={singleChatView ? queryParamsObj?.professionalName : ""}
            />
          ))}

          <div ref={msgEndRef} />
        </Content>
      </div>

      {showInput && !(supportChat && queryParamsObj?.status !== "Pending") && <ChatInput disabled={loading} handleSendMessage={sendMessage}/>}
    </div>
  );
}
