import React, { useState } from "react";
import { apiHeader, mergeClass } from "../../utils/helper";
import AppAvatar from "../AppAvatar";
import { Button } from "../Button/Button";
import classes from "./SubAdminCard.module.css";
import { Post } from "../../Axios/AxiosFunctions";
import { baseURL } from "../../config/apiUrl";
import { useSelector } from "react-redux";
import RenderToast from "../RenderToast";

export default function SubAdminCard({
  data,
  modal,
  modalClose = () => {},
  setContent = () => {},
  setUserId = () => {},
  setStatus = () => {},
}) {
  const { accessToken } = useSelector((state) => state?.authReducer);
  const name = data?.firstName + " " + data?.lastName;

  const [loading, setLoading] = useState(false);
  const [canClick, setCanClick] = useState(true);

  const resendLink = async () => {
    if (!canClick || loading || data?.isBlocked) return;

    setLoading(true);
    setCanClick(false);

    const response = await Post(
      baseURL(`User/resend-verification?email=${data?.email}&type=3`),
      {},
      apiHeader(accessToken)
    );
  
    if (response) {
      RenderToast({ message: "Verification link has been resent to sub-admin's email", type: "success" });
    }

    setLoading(false);

    setTimeout(() => {
      setCanClick(true);
    }, 120000); // 2 minutes

  };

  return (
    <div className={classes.innerBox}>
      <div className={classes.flexBetween}>
        <AppAvatar imageURI={data?.profileImagePath} />
        <Button
          className={classes?.btn}
          label={data?.isBlocked ? "Enable" : "Disable"}
          variant={data?.isBlocked ? "secondary" : "primary"}
          onClick={() => {
            setUserId(data?.id);
            setStatus(data?.isBlocked);
            modalClose(true);
            if (data?.isBlocked) {
              setContent("Enable");
            } else {
              setContent("Disable");
            }
          }}
        />
      </div>
      <div>
        <h6 className={mergeClass(classes.name, "maxLine2")} title={name}>
          {name}
        </h6>
        <h6
          className={mergeClass(classes.email, "maxLine1")}
          title={data?.email}
        >
          {data?.email}
        </h6>
        <p
          className={`${classes.resend} ${loading || !canClick || data?.isBlocked ? classes.disabled : ""}`}
          onClick={canClick && !loading && !data?.isBlocked ? resendLink : null}
          style={{
            cursor: loading || !canClick || data?.isBlocked ? "not-allowed" : "pointer",
            opacity: loading || !canClick || data?.isBlocked ? 0.5 : 1,
          }}
        >
          {loading ? "Resending..." : "Resend verification email"}
        </p>
      </div>
    </div>
  );
}
