import React, { useRef } from "react";
import classes from "./profilewitheditbutton.module.css";
import { mediaUrl } from "../../config/apiUrl";
import { HiPencil } from "react-icons/hi";
import { DemoProfile } from "../../constant/imagePath";
import RenderToast from "../RenderToast";

export const ProfileWithEditButton = ({
  updateImage,
  setUpdateImage,
  onClick,
  isEdit,
  className = "",
}) => {
  const inputRef = useRef(null);
  return (
    <>
      <div
        className={`${classes.profileEditContainer} ${className}`}
        onClick={(e) => {
          onClick();
        }}
      >
        {typeof updateImage === "object" ? (
          <div className={`${classes.profileEditImage_box}`}>
            <img
              className={`${classes.profileEditImage}`}
              src={
                updateImage !== null
                  ? URL.createObjectURL(updateImage)
                  : DemoProfile
              }
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = DemoProfile;
              }}
            />
          </div>
        ) : (
          typeof updateImage == "string" && (
            <div className={`${classes.profileEditImage_box}`}>
              <img
                className={`${classes.profileEditImage}`}
                src={updateImage}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = DemoProfile;
                }}
              />
            </div>
          )
        )}
        {isEdit && (
          <div className={`${classes.profileEditPen_box}`}>
            <HiPencil
              className={`${classes.profileEditPen}`}
              onClick={() => {
                inputRef.current.click();
              }}
              color={"#000"}
            />
            <input
              ref={inputRef}
              type="file"
              size="2000000"
              className={`${classes.file_upload_form3rd}`}
              onChange={(e) => {
                if (e.target.files?.length > 0) {
                  if (
                    !["image/jpeg", "image/png", "image/jpg"].includes(
                      e.target.files[0].type
                    )
                  ) {
                    return RenderToast({
                      type: "error",
                      message: `Please upload a valid image. [jpg and png formats only]`,
                    });
                  }
                  // max size 2MB
                  if (e.target.files[0]?.size / 1024 / 1024 > 2)
                    return RenderToast({
                      type: "error",
                      message: `Please upload a valid image. [Max size: 2MB]`,
                    });
                  setUpdateImage(e.target.files[0]);
                }
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};
