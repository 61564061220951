import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { FaPlus } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "../Button/Button";
import classes from "./ProfileNameEmail.module.css";

const ProfileNameEmail = ({
  showBtn = false,
  buttonLabel = "Create Sub Admin",
  onAddPost=()=>{},
  onClick=()=>{},
}) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state?.authReducer);

  useEffect(() => {
    if (!user) {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }, []);

  return (
    <>
      {user ? (
        <div className={classes.heroHeader}>
          <Container>
            <div className={classes.container}>
              <div className={classes.mainBox}>
                <div className={classes.imgBox}>
                  <img
                    src={
                      user?.profileImagePath
                        ? user?.profileImagePath
                        : "/images/avatar.png"
                    }
                    className={classes.img}
                    alt=""
                  />
                </div>{" "}
                <div className={classes.coloumnFlex}>
                  <p>
                    {user?.firstName} {user?.lastName}
                    {/* Admin */}
                  </p>
                  <span>
                    {/* admin@admin.com */}
                    {user?.email}
                  </span>
                </div>
              </div>
              <div className={classes.action}>
                {showBtn && (
                  <Button
                    onClick={onClick}
                    rightIcon={<FaPlus size={20} />}
                    variant="secondary"
                  >
                    {buttonLabel}
                  </Button>
                )}
              </div>
            </div>
          </Container>
        </div>
      ) : null}
    </>
  );
};

export default ProfileNameEmail;
