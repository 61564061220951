import React from "react";
import classes from "./noData.module.css";
import animationData from "../../assets/animation/noData.json";
import { ImSearch } from "react-icons/im";

function NoData({
  text = "No Data Found",
  className,
  animationPath = animationData,
}) {
  return (
    <div
      className={[classes.noDataContainer, className && className].join(" ")}
    >
      <ImSearch size={60} color={"var(--black-blue)"} />
      <p>{text}</p>
    </div>
  );
}

export default NoData;
