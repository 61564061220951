import React from "react";
import classes from "./StatsCardTwo.module.css";

import { mergeClass } from "../../utils/helper";
import { Percent } from "@mui/icons-material";
export default function StatsCardsTwo({ title, data, icon, Percent }) {
  return (
    <div className={classes.card}>
      <div className={classes.iconDiv}>{icon}</div>
      <div className={classes.textDiv}>
        <span className={mergeClass(classes.font, classes.title)}>{title}</span>
        <span className={mergeClass(classes.font, classes.amount)}>
          {data || 0}
          {Percent}
        </span>
      </div>
    </div>
  );
}
