import PropTypes from "prop-types";
import { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import classes from "./MobileHeader.module.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export const MobileHeader = ({
  customStyle,
  logo = "/images/Logo.png",
  headerLinks,
  HeaderButtons,
  user = null,
  onLogout = () => {},
  isLoggedIn = false,
  popover = [],
}) => {
  // const router = useRouter();
  const { notificationCount } = useSelector(
    (state) => state?.authReducer
  );
  // current page url path name
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const RenderListItem = ({
    icon,
    text,
    customClass,
    path,
    href,
    disabled = false,
  }) => {
    return (
      <div
        className={[
          classes.listItem,
          customClass,
          disabled && classes.navDisable,
        ].join(" ")}
        onClick={(e) => {
          if (text === "Logout") return onLogout();
          if (disabled) {
            return;
          }
          navigate(path);
        }}
      >
        {icon}
        <span className={classes.listItemText}>{text} {text == "Notifications" && notificationCount > 0 && `(${notificationCount})`}</span>
      </div>
    );
  };

  return (
    <>
      <div className={classes.headerMainDiv} id={"navMobileHeader"}>
        <div className={classes.header} style={{ ...customStyle }}>
          <div
            className={classes.imageContainer}
            onClick={() => navigate("/dashboard")}
          >
            <img
              src={logo}
              className={classes.logo}
              alt="logo"
              fill
              style={{ objectFit: "contain" }}
            />
          </div>
          <div className={classes.languageDropDownDiv}>
            <GiHamburgerMenu
              className={classes.hamburger}
              onClick={() => {
                toggleDrawer();
              }}
              fill={"var( --secondar-text-color )"}
            />
          </div>
        </div>
        <Drawer
          open={isOpen}
          onClose={toggleDrawer}
          direction="right"
          lockBackgroundScroll
        >
          <div className={classes.drawerContainer}>
            <div className={classes.drawerUserSection}>
              <div className={classes.drawerUserImage}>
                <img
                  src={logo}
                  className={classes.drawerLogo}
                  alt="logo"
                  fill
                  style={{ objectFit: "contain" }}
                />
              </div>
            </div>
            <div className={classes.drawerList}>
              <>
                {headerLinks?.map((item, index) => (
                  <RenderListItem
                    icon={item?.icon && item?.icon}
                    text={item?.label}
                    path={item?.value}
                    key={index}
                    // disabled={!["/professionals"].includes(item?.value)}
                  />
                ))}

                <hr
                  style={{
                    width: "100%",
                    marginBottom: "0px",
                  }}
                />
                {isLoggedIn ? (
                  popover?.map((item, index) => (
                    <RenderListItem
                      key={index}
                      text={item.label}
                      path={item.path}
                      // disabled={!["Logout"].includes(item?.label)}
                    />
                  ))
                ) : (
                  <>
                    {HeaderButtons?.map((item, index) => (
                      <RenderListItem
                        key={index}
                        text={item?.label}
                        path={item?.value}
                      />
                    ))}
                  </>
                )}
              </>
            </div>
          </div>
        </Drawer>
      </div>
    </>
  );
};

MobileHeader.propTypes = {
  customStyle: PropTypes.object,
};

MobileHeader.defaulProps = {};
