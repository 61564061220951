export const JOBS_DETAIL_TABS = [
  {
    label: "Details",
    value: "details",
  },
  {
    label: "Chats",
    value: "chats",
  },
];

export const SUPPORT_TABS = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Resolved",
    value: "resolved",
  },
];

export const supportData = [
  {
    key: 1,
    status: "pending",
    name: "Ahmed",
    email: "abc@yopmail.com",
    description:
      "Description for support request description for support request description for support request description for support request description for support request. Description for support request description for support request description for support request description for support request description for support request. Description for support request description for support request description for support request description for support request description for support request.",
    isChatInitiated: true,
    time: "04:50",
  },
  {
    key: 2,
    status: "resolved",
    name: "Ahmed",
    email: "abc@yopmail.com",
    description:
      "Description for support request description for support request description for support request description for support request description for support request.",
    isChatInitiated: false,
    time: "04:50",
  },
  {
    key: 3,
    status: "pending",
    name: "Ahmed",
    email: "abc@yopmail.com",
    description:
      "Description for support request description for support request description for support request description for support request description for support request.",
    isChatInitiated: false,
    time: "04:50",
  },
  {
    key: 4,
    status: "resolved",
    name: "Ahmed",
    email: "abc@yopmail.com",
    description:
      "Description for support request description for support request description for support request description for support request description for support request.",
    isChatInitiated: true,
    time: "04:50",
  },
];

export const HEADER = [
  {
    label: "Home",
    value: "/dashboard",
  },
  {
    label: "Professionals",
    value: "/professionals",
  },
  {
    label: "Rewards",
    value: "/rewards",
  },
  {
    label: "Clients",
    value: "/users",
  },
  {
    label: "Sub Admins",
    value: "/sub-admins",
  },
  {
    label: "Requests",
    value: "/requests",
  },

  {
    label: "Category",
    value: "/category",
  },
  {
    label: "Wallet",
    value: "/wallet",
  },
  {
    label: "Questionnaire",
    value: "/questionnaire",
  },
  {
    label: "Subscriptions",
    value: "/subscriptions",
  },
];

export const HEADER_SUB_ADMIN = [
  {
    label: "Home",
    value: "/dashboard",
  },
  {
    label: "Professionals",
    value: "/professionals",
  },
  {
    label: "Rewards",
    value: "/rewards",
  },
  {
    label: "Clients",
    value: "/users",
  },
  {
    label: "Requests",
    value: "/requests",
  },

  {
    label: "Category",
    value: "/category",
  },
  {
    label: "Wallet",
    value: "/wallet",
  },
  {
    label: "Questionnaire",
    value: "/questionnaire",
  },
  {
    label: "Subscriptions",
    value: "/subscriptions",
  },
];

export const WALLET_TABS = [
  {
    label: "Service Payments",
    value: "service_payments",
  },
  {
    label: "Session Payments",
    value: "session_payments",
  },
];

export const POPOVER = [
  { label: "Profile Setting", path: "/settings" },
  { label: "Notifications", path: "/notifications" },
  { label: "Support", path: "/support" },
  { label: "Manage Zoom", path: "/manage-zoom" },
  { label: "CMS", path: "/cms" },
  { label: "Logout", path: "" },
];

export const CATEGORIES = [
  {
    image: "/images/free-learning.png",
  },
  {
    image: "/images/advance-lms.png",
  },
  {
    image: "/images/certificates.png",
  },
];

export const PRO_SERVICE_TABS = [
  {
    label: "Details",
    value: "details",
  },
  {
    label: "Requests",
    value: "requests",
  },
];

export const PRO_TABS = [
  {
    label: "Details",
    value: "details",
  },
  {
    label: "Portfolio",
    value: "portfolio",
  },
  {
    label: "Questionnaires",
    value: "questionnaires",
  },
  {
    label: "Services",
    value: "services",
  },
  {
    label: "Jobs",
    value: "jobs",
  },

  {
    label: "Chats",
    value: "chats",
  },
  {
    label: "Referrals",
    value: "referrals",
  },
  {
    label: "Reviews",
    value: "reviews",
  },
  {
    label: "Sessions",
    value: "sessions",
  },
  {
    label: "Service Categories",
    value: "categories",
  },
];
export const SESSION_DETAIL_PAGE_TAB = [
  { label: "Requests", value: "requests" },
  { label: "Recordings", value: "recordings" },
];
export const PRO_TABLE = [
  { email: "sampleprofessional1@example.com", status: "Approved" },
  { email: "sampleprofessional2@example.com", status: "Pending" },
  { email: "sampleprofessional3@example.com", status: "Approved" },
];

export const PRO_HEADINGS = {
  heading: "Professionals Verification",
  email: "Email",
  status: "Status",
};

export const PRO_TABLE_TWO = [
  { email: "sampleprofessional1@example.com", status: "Pending" },
  { email: "sampleprofessional2@example.com", status: "Approved" },
  { email: "sampleprofessional3@example.com", status: "Pending" },
];

export const PRO_HEADINGS_TWO = {
  heading: "Clients Verification",
  email: "Email",
  status: "Status",
};

export const RELATED_SOLUTION = [
  {
    id: 1,
    heading1: "Industries",
    text1: "Cannabis Law",
    text2: "Life Sciences",
    text3: "Mining",
    text4: "Private Client Services",
  },
  {
    id: 2,
    heading2: "Practices",
    text1: "Cannabis Law",
    text2: "Life Sciences",
    text3: "Mining",
    text4: "Private Client Services",
  },
  {
    id: 3,
    heading3: "Markets",
    text1: "Cannabis Law",
    text2: "Life Sciences",
  },
];

export const ACHIEVEMENT_CARD_DATA = [
  {
    id: 1,
    image: "/images/image.png",
    title:
      "Recognized in the Canadian Legal Lexpert Directory in Corporate Mid-Market",
    date: "2022 TO 2024",
    description:
      "Supporting Canada’s dynamic emerging technology companies’ business, finance and legal challenges.",
  },
  {
    id: 2,
    image: "/images/image.png",
    title:
      "Recognized in the Canadian Legal Lexpert Directory in Corporate Mid-Market",
    date: "2022 TO 2024",
    description:
      "Supporting Canada’s dynamic emerging technology companies’ business, finance and legal challenges.",
  },
  {
    id: 3,
    image: "/images/image.png",
    title:
      "Recognized in the Canadian Legal Lexpert Directory in Corporate Mid-Market",
    date: "2022 TO 2024",
    description:
      "Supporting Canada’s dynamic emerging technology companies’ business, finance and legal challenges.",
  },
];

export const CLIENT_CARD_DATA = [
  {
    id: 1,
    image: "/images/image.png",
    title:
      "Recognized in the Canadian Legal Lexpert Directory in Corporate Mid-Market",
    date: "Deal: Oct 2024",
    description:
      "Supporting Canada’s dynamic emerging technology companies’ business, finance and legal challenges.",
  },
  {
    id: 2,
    image: "/images/image.png",
    title:
      "Recognized in the Canadian Legal Lexpert Directory in Corporate Mid-Market",
    date: "Deal: Oct 2024",
    description:
      "Supporting Canada’s dynamic emerging technology companies’ business, finance and legal challenges.",
  },
  {
    id: 3,
    image: "/images/image.png",
    title:
      "Recognized in the Canadian Legal Lexpert Directory in Corporate Mid-Market",
    date: "Deal: Oct 2024",
    description:
      "Supporting Canada’s dynamic emerging technology companies’ business, finance and legal challenges.",
  },
];
export const Referral = [
  {
    label: "Clients",
    value: "user",
  },
  {
    label: "Professionals",
    value: "professionals",
  },
];
