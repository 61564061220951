import React, { useState } from 'react'
import classes from "./SubscriptionCard.module.css";
import { GrCurrency } from "react-icons/gr";
import { BiSolidEditAlt } from "react-icons/bi";
import EditSubscriptionModal from '../../modals/EditSubscriptionModal/EditSubscriptionModal';

export default function SubscriptionCard({plan , getData}) {
  const [show, setShow] = useState(false);

  function handleClickEdit() {
    setShow((prev) => !prev);
  }

    return (
      <div className={classes.plansCard}>
        <div className={classes.title}>
          <div className={classes.iconDiv}><GrCurrency size={20} color='white'/></div>
          <p>{plan?.frequencyName}</p>
        </div>
        <div className={classes.detailsDiv}>
         <div className={classes.amountsDiv}>
          <p>Amount</p>
          <div className={classes.priceDiv}>
          <h3>$ {plan?.amount}</h3>
          <p>{plan?.frequencyName == "Monthly" ? "/month" : "/year"}</p>
          </div>
         </div>
         <div className={classes.editIcon}>
          <BiSolidEditAlt size={24} color='#417b7c' onClick={handleClickEdit}/>
         </div>
        </div>
        {show && <EditSubscriptionModal show={show} setShow={setShow} id={plan?.id} getData={getData} />}
      </div>
    );
}
