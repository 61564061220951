import React from 'react'
import classes from './UserNotificationCard.module.css';
import { FaArrowRightLong } from "react-icons/fa6";
import { MdOutlineAccountCircle } from "react-icons/md";

export default function UserNotificationCard({ image, name, email, date, message, notificationType }) {
  return (
    <div
    className={`${classes.notification_item} d-flex justify-content-between`}
  >
    <div className="d-flex flex-column gap-1">
    <div className="d-flex align-items-center">
      {/* {image ? <img
        src={image}
        alt={`${name}'s avatar`}
        className={classes.profile_image}
      /> :  */}
      <MdOutlineAccountCircle size={50} color="#36696B" style={{marginRight: "15px"}} />
      <div className={classes.profile_details}>
        <p className={classes.profile_name}>{name}</p>
        <p className={classes.profile_email}>{email}</p>
      </div>
    </div>
    <p className={classes.message}>{message}</p>
    </div>
    {/* <div onClick={() => notificationRoute() } style={{ cursor: "pointer" }} >
      <FaArrowRightLong />
    </div> */}
  </div>
  )
}
