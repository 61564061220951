import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FaCheck } from "react-icons/fa6";
import { PiPercent } from "react-icons/pi";
import { RxCross2 } from "react-icons/rx";
import { useSelector } from "react-redux";
import validator from "validator";
import { Post, Put } from "../../Axios/AxiosFunctions";
import { baseURL } from "../../config/apiUrl";
import { apiHeader } from "../../utils/helper";
import { Input } from "../Input/Input";
import RenderToast from "../RenderToast";
import classes from "./EditCategoryModal.module.css";

const EditCategoryModal = ({
  show,
  setShow,
  indivisualCategory,
  isServiceCategory = false,
  setData = () => {},
  userId="",
  getData = () => {}
}) => {
  const { accessToken } = useSelector((state) => state?.authReducer);

  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: isServiceCategory ? indivisualCategory?.serviceCategoryName : indivisualCategory?.name,
    number: indivisualCategory?.commission,
  });

  const handleChange = (name, value) => {
    if (name === "number" && value > 60) return;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    if (!formData.name || !formData.number) {
      return RenderToast({ type: "error", message: "Input all fields" });
    }
    if (formData.number < 0 || formData.number > 100) {
      return RenderToast({
        type: "error",
        message: "Commission has to be in range of 0 to 100",
      });
    }
    if (!validator.isNumeric) {
      return RenderToast({
        type: "error",
        message: "Input Commisions in number",
      });
    }
    setLoading(true);

    const apiFunc = isServiceCategory && !indivisualCategory?.isOverridden ? Post : Put

    const params = {
      id: indivisualCategory?.id,
      name: !isServiceCategory ? formData.name : null,
      commission: parseFloat(formData.number),
      userId: apiFunc == Post ? userId : null,
      serviceCategoryId: apiFunc == Post ? indivisualCategory?.serviceCategoryId : null
    };

    const res = await apiFunc(
      baseURL(`${isServiceCategory ? "ProfessionalServiceCategoryCommission" : "ServiceCategory"}`),
      params,
      apiHeader(accessToken)
    );
    if (res) {
      if(apiFunc === Post) params.isOverridden = true;
      RenderToast({ type: "success", message: "Successfully Updated" });
      setFormData("");
      setShow(false);

      setData((prev) => {
        const findIndex = prev?.findIndex(
          (item, index) => item?.id === indivisualCategory?.id
        );
        const _prev = [...prev];
        _prev[findIndex] = { ..._prev[findIndex], ...params };
        return _prev;
      });

      if(apiFunc === Post) getData({});

    }
    setLoading(false);
  };

  return (
    <>
      <style jsx>{`
        .modal-content {
          width: 100%;
          border-radius: 20px;
          overflow: hidden;
        }
        .modal-body {
          padding: 24px;
        }
        .modal-header {
          flex-direction: column;
          background: var(--black-color);
          border-bottom: none;
          padding: 0.75rem;
        }
        .name {
          font-size: 18px;
          color: var(--black-color);
        }
      `}</style>
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Body>
          <h6>Edit Category </h6>
          <div className={classes.formContainer}>
            <div className={`${classes.form} ${classes.formWidth}`}>
              <Input
                type="text"
                placeholder="Name"
                className={classes.input}
                value={formData.name}
                setter={(value) => handleChange("name", value)}
                disabled={isServiceCategory}
              />

              <Input
                type="decimal"
                placeholder="Commission"
                className={classes.input}
                rightIcon={<PiPercent />}
                value={formData.number}
                setter={(value) => handleChange("number", value)}
              />

              <div className={classes.actions}>
                <button
                  onClick={handleSubmit}
                  className={[classes.approveBtn, classes.actionBtn].join(" ")}
                >
                  <FaCheck color="green" />
                </button>
                <button
                  type="button"
                  className={[classes.rejectBtn, classes.actionBtn].join(" ")}
                  onClick={() => setShow(false)}
                >
                  <RxCross2 color="red" />
                </button>
              </div>
            </div>
          </div>
          {/* <div className={classes.content}>
            <div className={classes.mainDiv}>
              <p className={[classes.message].join(" ")}>{subTitle}</p>
            </div>
            <div className={classes.btnsBox}>
              <Button
                varient="white"
                className={classes.yesBtn}
                onClick={onClick}
                disabled={loading}
              >
                {loading ? "Wait" : "Yes"}
              </Button>
              <Button
                varient="black"
                className={classes.noBtn}
                onClick={async () => {
                  setShow(false);
                }}
                disabled={loading}
              >
                No
              </Button>
            </div>
          </div> */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditCategoryModal;
